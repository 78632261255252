<template>
    <div class="image-block-two">
        <figure>
            <img :src="element.one.asset.url" :alt="element.one.asset.altText">
            <caption v-if="element.one.asset.description">{{ element.one.asset.description }}</caption>
        </figure>
        <figure>
            <img :src="element.two.asset.url" :alt="element.two.asset.altText">
            <caption v-if="element.two.asset.description">{{ element.two.asset.description }}</caption>
        </figure>
    </div>
</template>

<script>
export default {
    name: 'ImageTwo',
    props: {
        element: Object
    }
}
</script>

<style lang="scss" scoped>
.image-block-two {
    display: flex;
    margin-bottom: $s5;
    max-width: 1200px;
    
    figure {
        display: flex;
        margin: 0 10px 0 10px;
        position: relative;
        width: 50%;
        padding-left: 3.75rem;
        
        img {
            height: 100%;
            margin-right: 10px;
            width: 100%;
            object-fit: cover;
            aspect-ratio: 1/1;
        }

        caption {
            bottom: -30px;
            position: absolute;
        }

        @media (max-width: 48em) {
            height: 300px;
            margin: 0;
            padding: $s1 0;
            width: 100%;

            img {
                margin-right: 0;
                width: 100%;
            }
        }
    }

    @media (max-width: 48em) {
        display: block;
        padding: $s1 0;
        margin-bottom: 0;
    }
}

.image-block-two.four-img {
    figure {
        img {
            max-width: 50%;
        }
    }
}
</style>