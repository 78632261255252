<template>
<div id="app">
    <Header :class="{ 'unpinned': scrolled }" v-on:scroll="onScroll" class="header" />
    <Cookies />
    <main>
        <router-view />
    </main>
    <Footer />
</div>
</template>

<script>
import Cookies from './components/Cookies.vue';
import Header from './components/Header.vue'
import Footer from './components/Footer.vue'

export default {
    name: 'App',
    components: {
        Cookies,
        Header,
        Footer,
    },
    data() {
        return {
            location: null,
            scroll_last_position: 0,
            scroll_limit_position: 50,
            scrolled: false,
        };
    },
    methods: {
        created() {
            window.addEventListener("scroll", this.onScroll);
        },
        destroyed() {
            window.addEventListener("scroll", this.onScroll);
        },
        metaInfo: function () {
            return {
                title: '',
                titleTemplate: '%s | Upswell',
                meta: [
                    { rel: 'canonical', href: location, id: 'rel-con' },

                    { property: 'og:type', content: 'website', id: 'og-type' },
                    { property: 'og:locale', content: 'en', id: 'og-locale' },
                    { property: 'og:url', content: location, id: 'og-url' },

                    // TODO find image
                    // { property: 'og:image', content: `${window.location.host}${this.$i18n.t(`main.${this.site}.preview_image`)}`, id: 'og-image' }
                ]
            }
        },
        onScroll() {
            if (this.scroll_last_position < window.scrollY && this.scroll_limit_position < window.scrollY) {
                this.scrolled = true;
            }
            if (this.scroll_last_position > window.scrollY) {
                this.scrolled = false;
            }
            this.scroll_last_position = window.scrollY;
        }
    },
    watch:{
        $route (to, from) {
            this.location = window.location.href
        }
    }
}
</script>

<style lang="scss" scoped>
.header {
  background-color: $upswell-blue;
  position: fixed;
  transition: transform .3s linear;
  will-change: transform;
  width: 100%;
  z-index: 6;
  height: $header-height;
}

.unpinned {
  transform: translateY(-100%);
}

main {
  padding-top: $header-height;
}
</style>