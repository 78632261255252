<template>
    <div class="value-wrapper d-flex flex-column align-items-center">
        <div class="icon-wrapper">
            <img :src=image alt="" role="presentation" />
        </div>
        <div class="value-text">
            <h5 class="h5-compact">{{ header }}</h5>
        </div>
        <p class="small">
            <vue-markdown>{{ text }}</vue-markdown>
        </p>
    </div>
</template>

<style lang='scss' scoped>
.value-wrapper {
    text-align: center;
    width: 100%;
    padding: $s2 0 0 0;

    .icon-wrapper {
        margin-bottom: $s2;
        height: 150px;
        width: 150px;

        img {
            height: 100%;
            object-fit: scale-down;
            width: 100%;
        }
    }

    p {
        width: 100%;
        margin-bottom: 0;
    }
}

.value-text {
    height: 0;
    padding-bottom: $s4;
}

@media (max-width: 767px) {
    .value-text {
        padding-bottom: $s2;
    }
    .value-wrapper {
        margin-right: 0;
        padding: $s4 0 $s2 0;
        width: 100%;
    }
}
</style>

<script>
import VueMarkdown from 'vue-markdown'

export default {
    name: 'Value',
    props: {
        image: String,
        header: String,
        text: String
    },
    components: {
        VueMarkdown
    },
};
</script>