<template>
<svg width="50" height="50" viewBox="0 0 50 50" xmlns="http://www.w3.org/2000/svg">
    <rect width="50" height="50" transform="matrix(-1 0 0 1 50 0)"/>
    <path d="M27 17.929L19.9289 25L27 32.0711" stroke="white" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
</svg>
</template>

<style>

</style>

<script>
export default {
    name: 'IconPrevious',
};
</script>
