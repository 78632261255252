<template>
    <section id="home">
        <div v-if="loading">
            <Loading />
        </div>
        <div v-else-if="content">

            <!-- Block: Leadin -->
            <section id="block-leading" class="leadin">
                <h1 class="hide">{{ content.leadin }}</h1>
                <div aria-hidden="true" class="desktop-leadin">
                    <div class="line"><h1 class="side-margins">{{ content.leadin.split(' ').slice(0, 6).join(' ') }}</h1></div>
                    <div class="line"><h1 class="side-margins">{{ content.leadin.split(' ').slice(6, 12).join(' ') }}</h1></div>
                    <div class="line"><h1 class="side-margins">{{ content.leadin.split(' ').slice(12).join(' ') }}</h1></div>
                </div>
                <div aria-hidden="true" class="hide-desktop">
                    <div class="line"><h1 class="side-margins">{{ content.leadin.split(' ').slice(0, 3).join(' ') }}</h1></div>
                    <div class="line"><h1 class="side-margins">{{ content.leadin.split(' ').slice(3, 6).join(' ') }}</h1></div>
                    <div class="line"><h1 class="side-margins">{{ content.leadin.split(' ').slice(6 , 9).join(' ') }}</h1></div>
                    <div class="line"><h1 class="side-margins">{{ content.leadin.split(' ').slice(9, 12).join(' ') }}</h1></div>
                    <div class="line"><h1 class="side-margins">{{ content.leadin.split(' ').slice(12).join(' ') }}</h1></div>
                </div>
            </section>

            <!-- Heading -->
            <h2 class="h6 label rotate hide-small">Our Purpose</h2>

            <!-- Block: Sections -->
            <section class="purpose-container">
                <div v-for="(entry, index) in content.sections" :key="entry.id" class="purpose-block">
                    <router-link :to="{ name: 'projects', query: {theme: entry.theme.slug.current}}">
                        <div :class="customClass(index)" class="d-flex borders">
                            <p class="index">0{{ index + 1 }}</p>
                            <div class="text-image pt-2">
                                <h2 class="m-0 title-line-one">{{ entry.theme.title.split(' ')[0] }}</h2>
                                <h2 class="pl-5">{{ entry.theme.title.split(' ').slice(1).join(' ') }}</h2>
                                <div class="small-image">
                                    <img :src="entry.image.asset.url" :alt="entry.image.asset.altText">
                                </div>
                            </div>
                        </div>
                    </router-link>
                    <div :class="customImageClass(index)">
                        <video :src="entry.video.asset.url" autoplay loop muted playsinline />
                    </div>
                </div>
            </section>
            
            <!-- Block: Partner Logos -->
            <div class="border-bottom-fw" aria-hidden="true"></div>
            <section class="cheater-margins partner-content py-3">
                <p class="py-1 large">{{ content.client_leadin }}</p>
                <div class="partner-logos">
                    <div v-for="(logo, index) in content.clients" :key="index">
                        <img :src="logo.logo_url" :alt="logo.name">
                    </div>
                </div>
            </section>

            <!-- Block: Call to Action -->
            <div class="border-bottom-fw" aria-hidden="true"></div>
            <CallToAction
                 :header="content.services_leadin"
                 buttonText="Take a look"
                 buttonURL="/work" />
        </div>
        <JsonLD :data="json_ld" />
    </section>
</template>

<script>
import client from '../sanity'

import CallToAction from '../components/CallToAction.vue'
import JsonLD from '../components/JsonLD.vue'
import Loading from '../components/Loading.vue'

export default {
    components: {
        CallToAction,
        JsonLD,
        Loading
    },
    data() {
        return {
            'loading': false,
            'content': null
        }
    },
    computed: {
        json_ld() {
            if (!this.content) return;
            return {
                "@context": "http://schema.org",
                "@type": "WebPage",
                name: this.title,
                description: this.description,
                url: window.location.href
            }
        }
    },
    methods: {
        customClass(index) {
            if (index === 0) {
                return 'main-item one diag-bottom'
            } else if (index === 1) {
                return 'main-item two diag-top'
            } else if (index === 2) {
                return 'main-item three'
            } else {
                return 'main-item four diag-top'
            }
        },
        customImageClass(index) {
            if (index === 0) {
                return 'large-image one'
            } else if (index === 1) {
                return 'large-image two'
            } else if (index === 2) {
                return 'large-image three'
            } else {
                return 'large-image four'
            }
        }
    },
    metaInfo() {
        if (!this.content) return;
        return {
            title: this.content.title,
            meta: [
                { name: 'description', content: this.content.description },
                { property: 'og:title', content: this.content.title, id: 'og-title' },
                { property: 'og:description', content: this.content.description, id: 'og-desc' },
                { property: 'og:image', content: this.content.image ? this.content.image.asset.url : '', id: 'og-image' }
            ]
        }
    },
    mounted() {
        if(this.page) {
            return
        }

        this.loading = true

        const query = `*[_type == "homepage" && slug.current == "home"][0]{
            title, description, leadin, image{asset->{url}},
            sections[]{theme->{title, slug}, image{asset->{url, altText}}, video{asset->{url, mimeType}}},
            client_leadin, clients[]->{slug, name, 'logo_url': logo.asset->url},
            services_leadin
        }`
        
        client.fetch(query).then((results) => {
            this.content = results
            this.loading = false
        })
    }
}
</script>


<style scoped lang='scss'>
figure video {
    mix-blend-mode: screen;
}

.index {
    padding-left: $s2;
    position: absolute;
    top: 0;
    font-size: 1.125rem;
    letter-spacing: 5px;
    color: $upswell-light-blue;
}

.label {
    font-size: 1rem;
    padding-top: 60px;
    margin-top: 3rem;   
}

.large-image {
    overflow: hidden;
    position: absolute;
    top: 100px;
    width: 500px;
    height: 500px;

    video {
        width: 100%;
    }

    &.one {
        left: -10px;
        margin-top: 1.5rem;
    }

    &.two {
        top: 50px;
        left: 41%;
    }

    &.three {
        left: -10px;
        top: 70px;
    }

    &.four {
        left: 55%;
        top: 22%;
    }

    @media (max-width: 1050px) {
        &.two {
            left: 35%;
        }

        &.four {
            left: 40%
        }
    }

    @media (max-width: 800px) {
        &.one, &.two, &.three, &.four {
            height: auto;
            margin: 0;
            padding: 0;
            position: unset;
            width: 100%;
        }
    }
}

.leadin {
    padding-bottom: $s5;
    color: #fff;

    .line {
        border-bottom: 1px solid $upswell-medium-blue;
        min-height: 100px;
        white-space: nowrap;

        h1 {
            font-size: 3.1rem;
            margin-bottom: 0;
            margin-top: 0;
        }
    }

    @media(max-width: 1200px) {
        .line {
            border-bottom: 1px solid $upswell-medium-blue;
            padding: 1rem 0;
            min-height: 100px;
            max-height: 110px;
            box-sizing: border-box;
            white-space: nowrap;

            h1 {
                font-size: 4vw;
                line-height: 6vh;
                margin-bottom: 0;
                margin-top: 0;
            }
        }
    }
    
    @media (max-width: 800px) {
        .desktop-leadin {
            display: none;
        }
        .line {
            min-height: 80px;

            h1 {
                font-size: 1.8rem;
                display: inline-block;
                padding-right: 1rem;
            }
        }
    }
    @media (max-width: 400px) {
        .line {
            h1 {
                font-size: 1.7rem;
            }
        }
    }
}


.main-item {
    border-top: 1px solid $upswell-medium-blue;
    height: 600px;
    margin: $s2 0 $s6 $s4;
    position: relative;
    transition: all .2s;

    .text-image {
        box-sizing: border-box;
    }

    &:hover {
        border-color: $upswell-light-blue;

        &.diag-bottom {
            background: url("data:image/svg+xml;utf8,<svg xmlns='http://www.w3.org/2000/svg' version='1.1' preserveAspectRatio='none' viewBox='0 0 100 100'><line x1='0' y1='100' x2='100' y2='0' stroke='%23B1BCCA' vector-effect='non-scaling-stroke'/></svg>");
            background-repeat: no-repeat;
            background-position: center center;
            background-size: 100% 100%, auto;
        }

        &.diag-top {
            background: url("data:image/svg+xml;utf8,<svg xmlns='http://www.w3.org/2000/svg' version='1.1' preserveAspectRatio='none' viewBox='0 0 100 100'><line x1='0' y1='0' x2='100' y2='100' stroke='%23B1BCCA' vector-effect='non-scaling-stroke'/></svg>");
            background-repeat: no-repeat;
            background-position: center center;
            background-size: 100% 100%, auto;
        }
    }

    &.one {
        .index {
            left: 0;
            top: 30px;
            font-size: 16px;
        }

        .text-image {
            margin-left: 55%;

            .small-image {
                margin-top: 5.5rem;
                margin-left: 2rem;
                border-radius: 50%;
            }
        }
    }

    &.two {
        height: 600px;

        .index {
            padding-left: 0;
            padding-right: $s2;
            right: 10px;
            top: 90px;
            font-size: 16px;

        }

        .text-image {
            height: 360px;
            margin-top: $s2;
            left: -75px;
            position: relative;
            width: 40%;

            .text {
                width: 80%;
            }

            .small-image {
                bottom: -50px;
                left: 40px;
                overflow: hidden;
                position: absolute;
            }
        }
    }

    &.three {
        border-left: 0;
        border-bottom: 0;
        width: 57%;
        margin-bottom: 12rem;
        margin-left: 37%;
        flex-direction: row-reverse;
        height: 500px;
        margin-right: $s3;
        margin-top: $s1;

        .index {
            left: 27%;
            top: 12%;
            font-size: 16px;

            @media(max-width: 1400px) {
                top: 5%;
            }
        }

        .text-image {
            margin-top: 0;
            width: 60%;
            padding: $s2 0 0 $s1;

            .text {
                padding: 0;
                width: 100%;
            }

            h2 {
                padding: 0;
                margin-left: 2rem;
            }

            .small-image {
                bottom: 0;
                position: absolute;
            }
        }
    }

    &.four {
        height: 500px;
        margin: 1rem 0 12rem 0rem;
        width: 85%;

        .index {
            padding-left: 0;
            padding-right: $s3;
            right: 0;
            top: 20px;
        }

        .text-image {
            height: 360px;
            margin-top: $s2;
            left: -20px;
            position: relative;
            width: 40%;


            .text {
                width: 100%;
            }

            .small-image {
                bottom: -27%;
                left: 8rem;
                position: absolute;
                border-radius: 50%;
            }
        }
    }

    @media (max-width: 1200px) {
        .small-image {
            display: none;
        }
    }

    @media (max-width: 1050px) {
        &.one {
            .text-image {
                h2 {
                    margin-left: 3rem;
                }
            }
        }

        .three {
            .text-image {
                h2 {
                    margin-left: rem;
                }
            }
        }
    }

    @media (max-width: 800px) {
        height: 300px;

        &.one, &.two, &.three, &.four {
            flex-direction: column;
            height: auto;
            margin: 0;

            .index {
                padding: $s2 $s2 0 $s2;
                position: unset;
            }

            .text-image {
                height: auto;
                margin: 0;
                padding: 0 $s2 $s2 $s2;
                position: unset;
                width: 100%;
            }
        }
    }
}

.partner-content {
    text-align: center;

    p {
        max-width: 580px;
        margin: 0 auto;
    }
}

.partner-logos {
    align-items: center;
    justify-content: center;
    display: flex;
    flex-wrap: wrap;
    padding: $s2 0;
    max-width: 1500px;
    margin: 0 auto;

    div {
        flex-basis: 20%;
        min-width: 150px;
    }
}


.purpose-block {
    margin-left: 8rem;
    margin-right: 6.5rem;
    position: relative;

    h2 {
        font-size: 42px;
    }

    a {
        text-decoration: none;
    }

    img {
        aspect-ratio: 1/1;
        object-fit: cover;
        height: 100%;
        width: 100%;
    }

    @media (max-width: 800px) {
        margin-left: 0;
        margin-right: 0;

        .borders {
            border-top: none;
        }
    }
}

.purpose-container {
    margin-bottom: $s5;

    @media (max-width: 800px) {
        margin-bottom: 0;
    }
}

.text-image {
    margin-top: $s2;
    padding-bottom: $s2;
    width: 50%;
    z-index: 1;

    h2 {
        background: $upswell-blue;
    }

    .text {
        padding-bottom: $s1;
        padding-left: $s4;
        width: 75%;
    }

    .small-image {
        height: 250px;
        width: 250px;
        overflow: hidden;

        img {
            height: 100%;
            object-fit: cover;
            width: 100%;
        }
    }

    @media (max-width: 1050px) {
        h2 {
            font-size: 2rem;
        }
    }

    @media (max-width: 800px) {
        padding-left: $s2;
        width: 95%;

        .text {
            padding-left: 0;
        }

        h2 {
            font-size: 28px;
            line-height: 32px;
        }
    }
}

@media (max-width: 520px) {
    .partner-logos {    
        margin-left: -1.5rem;
        margin-right: -1.5rem;
        
        div {
            flex-basis: 50%;
            min-width: unset;
        }
    }
}
</style>
