<template>
    <nav>
        <IconHamburger @click.native="toggleNav" class="hide-desktop display-mobile" />
        <!-- Desktop Menu -->
        <ul class="hide-mobile">
            <li>
                <router-link :to="{ name: 'projects' }" class="nav-link">WORK</router-link>
            </li>
            <li>
                <router-link :to="{ name: 'services' }" class="nav-link">SERVICES</router-link>
            </li>
            <li>
                <router-link :to="{ name: 'studio' }" class="nav-link">STUDIO</router-link>
            </li>
            <li>
                <router-link :to="{ name: 'contact' }" class="nav-link">CONTACT US</router-link>
            </li>
        </ul>
        <!-- Mobile Nav -->
        <div v-if="isNavOpen">
            <ul>
                <li @click="toggleNav">
                    <router-link :to="{ name: 'projects' }" class="nav-link">WORK</router-link>
                </li>
                <li @click="toggleNav">
                    <router-link :to="{ name: 'services' }" class="nav-link">SERVICES</router-link>
                </li>
                <li @click="toggleNav">
                    <router-link :to="{ name: 'studio' }" class="nav-link">STUDIO</router-link>
                </li>
                <li @click="toggleNav">
                    <router-link :to="{ name: 'contact' }" class="nav-link">CONTACT US</router-link>
                </li>
                <div class="nav-social hide-desktop d-flex flex-column side-margins pt-1">
                    <a href="https://www.instagram.com/helloupswell/?hl=en" target="_blank" class="pt-1">Instagram</a>
                    <a href="https://vimeo.com/upswell" target="_blank" class="pt-1">Vimeo</a>
                    <a href="https://www.linkedin.com/company/upswell/" target="_blank" class="pt-1">LinkedIn</a>
                </div>
                <div class="nav-social hide-desktop d-flex flex-column side-margins pt-2">
                    <router-link to="/p/privacy-policy" class="nav-link">Privacy Policy</router-link>
                </div>
            </ul>
        </div>
    </nav>
</template>

<script>
import IconHamburger from './icons/IconHamburger.vue'

export default {
    components: {
        IconHamburger
    },
    data() {
        return {
            isNavOpen: false,
        }
    },
    methods: {
        toggleNav() {
            this.isNavOpen = !this.isNavOpen;
            if (this.isNavOpen) {
                document.body.classList.add('disable-scroll');
            } else {
                document.body.classList.remove('disable-scroll');
            }
        }
    },
}
</script>


<style lang='scss'>
nav {
    ul {
        background-color: $upswell-blue;
        display: flex;
        justify-content: flex-end;
    }

    li {
        font-size: 1.2rem;
        padding-right: $s5;
    }

    li a:hover,
    li a.router-link-active,
    li a.router-link-exact-active {
        color: $upswell-white;
    }

    a {
        color: $upswell-light-blue;
        font-family: 'fraktion', sans-serif;
        text-decoration: none;
        text-transform: uppercase;
        &:hover {
            text-decoration: none;
        }
    }

    ul li:last-child {
        padding-right: 0;
    }

    @media (max-width: 940px) {
        .hide-mobile {
            display: none;
        }

        .display-mobile {
            display: block;
        }
    }



    @media (max-width: 940px) {
        ul {
            background-color: $upswell-blue;
            display: block;
            height: 100vh;
            left: 0;
            padding: 0;
            position: fixed;
            top: $s4;
            width: 100%;
            z-index: 100;

            li {
                border-bottom: 1px solid $upswell-medium-blue;
                padding: 1.5rem;

                @media (min-width: 768px) {
                    padding: 1.5rem 1.5rem 1.5rem 6.5rem;
                }
            }

            li:first-child {
                border-top: 1px solid $upswell-medium-blue;
            }
        }
    }
}

.nav-social {
    a {
        font-family: 'ibm-plex', sans-serif;
        text-transform: capitalize;
    }
}

.disable-scroll {
    height: 100%;
    overflow: hidden;
}
</style>
