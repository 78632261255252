<template>
    <div class="callout-block">
        <blockquote>
            <h5>{{ element.text }}</h5>
            <p></p>
        </blockquote>
    </div>
</template>

<script>
export default {
    name: 'PullQuote',
    props: {
        element: Object
    }
}
</script>

<style lang="scss" scoped>
.callout-block {
    display: grid;
    grid-template-columns: 35% 65%;

    blockquote {
        border-right: 1px solid $upswell-medium-blue;
        grid-column: 1;
        margin: 0 -1px 0 0;
        padding: 0 $s2 $s2 0;
        color: $upswell-white;

        h5 {
            width: 180%;
            max-width: 650px;
        }

        @media (max-width: 48em) {
            border: none;
            padding: 0 0 0 1rem;
            margin: $s2 0;

            h5 {
                width: 100%;
            }
        }
    }

    @media (max-width: 48em) {
        border-left: 1px solid $upswell-medium-blue;
        display: block;
    }
}
</style>