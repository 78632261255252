import Vue from 'vue'
import VueRouter from 'vue-router'

import Index from '../views/index.vue'
import Studio from '../views/studio.vue'
import Contact from '../views/contact.vue'
import Page from '../views/page.vue'
import Projects from '../views/projects.vue'
import Project from '../views/project.vue'
import Services from '../views/services.vue'
import NotFound from '../views/notFound.vue'

Vue.use(VueRouter)


/**
 * When adding routes to this application, ensure that routes are also added to `netlify.toml`
 * to ensure proper deep-linking.
 */
const routes = [
    {
        path: `/`,
        name: '/',
        component: Index
    },
    {
        path: `/work`,
        name: 'projects',
        component: Projects
    },
    {
        path: `/services`,
        name: 'services',
        component: Services
    },
    {
        path: `/projects/:slug`,
        name: 'project',
        component: Project
    },
    {
        path: `/contact`,
        name: 'contact',
        component: Contact
    },
    {
        path: `/studio`,
        name: 'studio',
        component: Studio
    },
    {
        path: `/p/:slug`,
        name: 'page',
        component: Page
    },
    {
        path: "*",
        name: 'not-found',
        component: NotFound
    }
]

const router = new VueRouter({
    mode: 'history',
    base: process.env.BASE_URL,
    routes,
    scrollBehavior(to, from, savedPosition) {
        return {x: 0, y: 0}
    }
})

export default router