<template>
    <a v-if="link.includes('@')" :href="`mailto:${link}`" class="round-btn">
        <div class="button">
            <span class="button-text">{{ text }}</span>
            <IconArrowRight></IconArrowRight>
        </div>
    </a>
    <a v-else :href="link" :target="external ? '_blank' : '_self'" class="round-btn">
        <div class="button">
            <vue-markdown class="button-text text">{{ text }}</vue-markdown>
            <IconArrowRight></IconArrowRight>
        </div>
    </a>
</template>

<script>
import IconArrowRight from './icons/IconArrowRight.vue'
import VueMarkdown from 'vue-markdown'

export default {
    name: 'Button',
    components: {
        IconArrowRight,
        VueMarkdown,
    },
    props: {
        link: String,
        text: String,
        external: {
            type: Boolean,
            default: false
        }
    },
};
</script>

<style lang='scss' scoped>
a.round-btn {
    display: block;
    text-decoration: none;

    .button {
        border: 1px solid $upswell-medium-blue;
        border-radius: 50%;
        margin-left: 0;
        margin-right: 0;
        height: 147px;
        width: 147px;
        display: flex;
        flex-direction: column;
        justify-content: center;
        transition: 0.5s;

        .text {
            text-align: center;
            width: 100%;
        }
    }

    svg {
        margin: 0 auto;
    }

    &:hover {
        color: $upswell-medium-blue;
        text-decoration: none;


        .button {
            background-color: $upswell-yellow;
        }

        span {
            color: $upswell-medium-blue;
        }

        svg {
            fill: $upswell-medium-blue;
        }
    }
}
</style>

