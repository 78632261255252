<template>
    <section id="page-page" :class="{'side-margins': !loading}">
        <div v-if="loading">
            <Loading />
        </div>
        <article v-else-if="content">
            <VueMarkdown>{{ content.content }}</VueMarkdown>
            <JsonLD :data="json_ld" />
        </article>
    </section>
</template>

<script>
import client from '../sanity'
import VueMarkdown from 'vue-markdown'

import JsonLD from '../components/JsonLD.vue'
import Loading from '../components/Loading.vue'

export default {
    components: {
        JsonLD,
        Loading,
        VueMarkdown
    },
    data() {
        return {
            'loading': false,
            'content': null
        }
    },
    computed: {
        json_ld() {
            return {
                "@context": "http://schema.org",
                "@type": "WebPage",
                name: this.title,
                description: this.description,
                url: window.location.href
            }
        }
    },
    metaInfo() {
        if (!this.content) return;
        return {
            title: this.content.title,
            meta: [
                { name: 'description', content: this.content.description },
                { property: 'og:title', content: this.content.title, id: 'og-title' },
                { property: 'og:description', content: this.content.description, id: 'og-desc' },
                { property: 'og:image', content: '', id: 'og-image' }
            ]
        }
    },
    async mounted() {
        this.loading = true

        const query = `*[_type == "page" && slug.current == "${this.$route.params.slug}"][0]`
        
        client.fetch(query).then((results) => {
            this.content = results
            this.loading = false
        })
    }
}
</script>

<style lang="scss" scoped>

article {
    max-width: 70%;
    margin: 3rem auto;

    @media (max-width: 48em) {
        max-width: unset;
    }
}

::v-deep {
    pre {
        white-space: pre-wrap;
    }

    ul, ol {        
        list-style: none;
        margin: 0;
        padding-left: 2.25rem;

        li {
            font-size: 1.125rem;
            line-height: 2rem;
            margin: 1.125rem 0;
        }
    }

    blockquote {
        padding-left: 2.25rem;
        line-height: 2rem;
        font-size: 1.5rem;
        max-width: 620px;
    }

    h1, h2, h3, h4 {
        margin-bottom: 1rem;
    }

    h1 {
        line-height: 4rem;
        margin-top: 3.25rem;
        margin-bottom: 2.25rem;
    }

    h2 {
        font-size: 2.625rem;
        line-height: 3rem;
        margin-top: 3rem;
    }

    h3 {
        font-size: 2.0rem;
        line-height: 2.5rem;
        margin-top: 2.5rem;
    }

    h4 {
        font-size: 1.55rem;
        line-height: 2.1rem;
        text-transform: uppercase;
        margin-top: 2.25rem;
    }

    a {
        line-break: anywhere;
    }
}
</style>