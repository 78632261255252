<template>
    <section class="cta-container d-flex">
        <h5>{{ header }}</h5>
        <Button :link="buttonURL" :text="buttonText"/>
        <p>{{ button }}</p>
    </section>
</template>

<script>
import Button from './Button.vue'

export default {
    name: 'CallToAction',
    props: {
        'button': String,
        'header': String,
        'buttonText': String,
        'buttonURL': String
    },
    components: {
        Button
    }
}
</script>

<style scoped lang='scss'>
.cta-container {
    color:  #fff;
    margin-left: $s2;
    padding: $s5;
    margin-bottom: -30px;

    h5 {
        margin-right: $s5;
        width: 75%;
    }
}

@media (max-width: 767px) {
    .cta-container {
        align-items: center;
        flex-direction: column;
        margin: 0;
        padding: $s2 0;
        width: 100%;
        
        h5 {
            margin: 0;
            padding: $s2 0;
            width: 90%;
        }
    }
}
</style>