<template>
<svg width="251" height="131" viewBox="0 0 251 131" fill="none" xmlns="http://www.w3.org/2000/svg">
<path fill-rule="evenodd" clip-rule="evenodd" d="M250.788 0.5L34.2183 0.51625L0.787537 130.5L250.788 57.0717V0.5ZM35.7889 2.08708L246.65 2.07083L143.079 55.5081L35.7889 55.5171V2.08708ZM249.217 2.63417V55.499L146.736 55.5081L249.217 2.63417ZM34.2183 7.26181V56.0064L5.25353 120.071L34.2183 7.26181ZM143.69 57.0789L244.843 57.0717L10.2256 125.941L143.69 57.0789ZM35.5684 57.0897L140.034 57.0789L3.79138 127.373L35.5684 57.0897Z" fill="white" fill-opacity="0.2"/>
<path fill-rule="evenodd" clip-rule="evenodd" d="M74.8459 38.4813L71.6614 41.8288C71.1168 42.3996 70.4559 42.6877 69.7188 42.6877H63.1174C62.3784 42.6877 61.7194 42.3996 61.1747 41.8288L57.9902 38.4813C57.4456 37.9105 57.1732 37.2155 57.1732 36.4394V15.4507H59.5825V36.2755C59.5825 36.4808 59.6605 36.6842 59.8149 36.8481L62.7288 39.9111C62.8832 40.0731 63.0393 40.156 63.2717 40.156H69.5626C69.7968 40.156 69.9512 40.0731 70.1073 39.9111L73.0195 36.8481C73.1756 36.6842 73.2537 36.4808 73.2537 36.2755V15.4507H75.6611V36.4394C75.6611 37.2155 75.3888 37.9105 74.8459 38.4813Z" fill="white"/>
<path fill-rule="evenodd" clip-rule="evenodd" d="M102.577 27.5383L100.597 29.6199C100.052 30.1925 99.431 30.4374 98.6939 30.4374H87.3139V42.6874H84.9047V15.4521H98.6939C99.431 15.4521 100.052 15.7385 100.558 16.2679L102.577 18.3927C103.12 18.9635 103.393 19.6171 103.393 20.4328V25.4963C103.393 26.2724 103.12 26.9657 102.577 27.5383ZM100.985 20.5553C100.985 20.3104 100.907 20.1483 100.753 19.9844L99.0806 18.2288C98.9263 18.0649 98.7701 17.9839 98.5377 17.9839H87.3139V27.9056H98.4996C98.6939 27.9056 98.8863 27.8228 99.0425 27.6607L100.753 25.8637C100.907 25.7016 100.985 25.5377 100.985 25.2928V20.5553Z" fill="white"/>
<path fill-rule="evenodd" clip-rule="evenodd" d="M128.91 38.4813L125.725 41.8288C125.181 42.3996 124.522 42.6877 123.783 42.6877H114.772C114.035 42.6877 113.374 42.3996 112.831 41.8288L108.831 37.6224L110.54 35.8685L114.383 39.9111C114.54 40.0731 114.696 40.156 114.928 40.156H123.628C123.861 40.156 124.017 40.0731 124.171 39.9111L127.085 36.8481C127.24 36.6842 127.318 36.4808 127.318 36.2755V32.152C127.318 31.7432 126.967 31.3759 126.58 31.3362L111.239 29.3339C109.879 29.1718 108.831 27.9474 108.831 26.4762V21.699C108.831 20.9229 109.102 20.2279 109.645 19.6571L112.831 16.3096C113.374 15.7388 114.035 15.4507 114.772 15.4507H123.783C124.522 15.4507 125.181 15.7388 125.725 16.3096L129.725 20.5142L128.017 22.2698L124.171 18.2291C124.017 18.0653 123.861 17.9842 123.628 17.9842H114.928C114.696 17.9842 114.54 18.0653 114.383 18.2291L111.471 21.2903C111.317 21.4541 111.239 21.6576 111.239 21.8629V25.9864C111.239 26.3952 111.549 26.7625 111.938 26.8021L127.318 28.8045C128.677 28.9666 129.725 30.1928 129.725 31.6622V36.4394C129.725 37.2155 129.453 37.9105 128.91 38.4813Z" fill="white"/>
<path fill-rule="evenodd" clip-rule="evenodd" d="M156.966 42.6622H153.432L148.591 17.3514L143.714 42.6622H140.18L134.917 15.2568H137.413L142.101 40.4426L146.863 15.2568H150.627L155.237 40.4426L160.078 15.2568H162.304L156.966 42.6622Z" fill="white"/>
<path fill-rule="evenodd" clip-rule="evenodd" d="M169.327 42.6622V15.2568H184.776V17.8044H171.694V27.1304H184.776V29.4424H171.694V40.1147H184.776V42.6622H169.327Z" fill="white"/>
<path fill-rule="evenodd" clip-rule="evenodd" d="M193.203 42.6622V15.2568H195.621V40.1147H209.355V42.6622H193.203Z" fill="white"/>
<path fill-rule="evenodd" clip-rule="evenodd" d="M214.973 42.6622V15.2568H217.391V40.1147H231.125V42.6622H214.973Z" fill="white"/>
</svg>
</template>

<style scoped>
svg {
    height: 100px;
    width: auto;
}
</style>

<script>
export default {
    name: 'IconLogoProjection',
};
</script>
