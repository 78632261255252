var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('ssr-carousel',{staticClass:"carousel",attrs:{"slides-per-page":1,"gutter":10,"loop":"","peek-left":"70","peek-right":"70","show-arrows":"","responsive":[
        {
            maxWidth: 787,
            peekLeft: 20,
            peekRight: 20,
        }]},scopedSlots:_vm._u([{key:"back-arrow",fn:function(ref){
        var disabled = ref.disabled;
return [_c('IconPrevious',{attrs:{"disabled":disabled}})]}},{key:"next-arrow",fn:function(ref){
        var disabled = ref.disabled;
return [_c('IconNext',{attrs:{"disabled":disabled}})]}}])},[_vm._l((_vm.items),function(slide){return _c('div',{key:slide._key,ref:"slide",refInFor:true,staticClass:"slide"},[_c('figure',[_c('img',{attrs:{"src":_vm.imageUrl(slide),"alt":slide.asset.altText}}),_c('caption',[_vm._v(_vm._s(slide.asset.description))])])])})],2)}
var staticRenderFns = []

export { render, staticRenderFns }