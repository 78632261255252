<template>
    <header>
        <div class="header-links side-margins d-flex">
            <router-link :to="{name: '/'}" class="nav-link"><Logo /></router-link>
            <Nav />
        </div>
    </header>
</template>

<script>
import Nav from './Nav.vue'
import Logo from './icons/IconLogo.vue';

export default {
    components: {
        Nav,
        Logo
    }
}
</script>

<style lang='scss'>
header {
    border-bottom: 1px solid $upswell-medium-blue;
}

.header-links {
    align-items: center;
    justify-content: space-between;
    padding: 1.5rem 0 1rem 0;
}

</style>