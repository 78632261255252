<template>
<svg width="153" height="25" viewBox="0 0 153 25" fill="none" xmlns="http://www.w3.org/2000/svg">
<path fill-rule="evenodd" clip-rule="evenodd" d="M15.4623 20.6332L12.6761 23.562C12.1995 24.0614 11.6213 24.3135 10.9764 24.3135H5.20069C4.55418 24.3135 3.97756 24.0614 3.50102 23.562L0.714817 20.6332C0.238272 20.1338 0 19.5257 0 18.8467V0.483154H2.10792V18.7033C2.10792 18.8829 2.17622 19.0609 2.31124 19.2043L4.86076 21.8841C4.99578 22.0259 5.13239 22.0984 5.33571 22.0984H10.8398C11.0447 22.0984 11.1797 22.0259 11.3163 21.8841L13.8643 19.2043C14.0009 19.0609 14.0692 18.8829 14.0692 18.7033V0.483154H16.1755V18.8467C16.1755 19.5257 15.9372 20.1338 15.4623 20.6332Z" fill="white"/>
<path fill-rule="evenodd" clip-rule="evenodd" d="M39.7252 11.0589L37.9922 12.8801C37.5156 13.3811 36.9724 13.5954 36.3275 13.5954H26.3709V24.3132H24.2629V0.484421H36.3275C36.9724 0.484421 37.5156 0.734918 37.9588 1.1981L39.7252 3.05714C40.2002 3.55656 40.4385 4.12845 40.4385 4.84213V9.2723C40.4385 9.95133 40.2002 10.5579 39.7252 11.0589ZM38.3321 4.94926C38.3321 4.735 38.2638 4.59321 38.1288 4.44984L36.6658 2.91377C36.5308 2.7704 36.3942 2.69951 36.1909 2.69951H26.3709V11.3803H36.1575C36.3275 11.3803 36.4958 11.3078 36.6325 11.166L38.1288 9.5937C38.2638 9.45191 38.3321 9.30854 38.3321 9.09428V4.94926Z" fill="white"/>
<path fill-rule="evenodd" clip-rule="evenodd" d="M62.7641 20.6332L59.9779 23.562C59.5014 24.0614 58.9247 24.3135 58.2782 24.3135H50.3946C49.7497 24.3135 49.1715 24.0614 48.6965 23.562L45.1971 19.8817L46.6918 18.3472L50.0547 21.8841C50.1913 22.0259 50.3279 22.0984 50.5312 22.0984H58.1432C58.3465 22.0984 58.4831 22.0259 58.6182 21.8841L61.1677 19.2043C61.3027 19.0609 61.371 18.8829 61.371 18.7033V15.0955C61.371 14.7379 61.0644 14.4165 60.7261 14.3818L47.3034 12.6299C46.1136 12.4881 45.1971 11.4168 45.1971 10.1297V5.94998C45.1971 5.27096 45.4338 4.66284 45.9087 4.16342L48.6965 1.23465C49.1715 0.735227 49.7497 0.483154 50.3946 0.483154H58.2782C58.9247 0.483154 59.5014 0.735227 59.9779 1.23465L63.4773 4.91333L61.9826 6.4494L58.6182 2.91408C58.4831 2.77071 58.3465 2.69982 58.1432 2.69982H50.5312C50.3279 2.69982 50.1913 2.77071 50.0547 2.91408L47.5067 5.59235C47.3717 5.73572 47.3034 5.91375 47.3034 6.09335V9.70114C47.3034 10.0588 47.575 10.3802 47.915 10.4148L61.371 12.1667C62.5608 12.3085 63.4773 13.3814 63.4773 14.667V18.8467C63.4773 19.5257 63.2391 20.1338 62.7641 20.6332Z" fill="white"/>
<path fill-rule="evenodd" clip-rule="evenodd" d="M87.311 24.2911H84.2191L79.9835 2.14608L75.7164 24.2911H72.6245L68.0197 0.313477H70.2035L74.3056 22.3491L78.4721 0.313477H81.7651L85.7981 22.3491L90.0337 0.313477H91.9818L87.311 24.2911Z" fill="white"/>
<path fill-rule="evenodd" clip-rule="evenodd" d="M98.126 24.2911V0.313477H111.643V2.54241H100.197V10.7019H111.643V12.7248H100.197V22.0622H111.643V24.2911H98.126Z" fill="white"/>
<path fill-rule="evenodd" clip-rule="evenodd" d="M119.016 24.2911V0.313477H121.132V22.0622H133.147V24.2911H119.016Z" fill="white"/>
<path fill-rule="evenodd" clip-rule="evenodd" d="M138.063 24.2911V0.313477H140.179V22.0622H152.194V24.2911H138.063Z" fill="white"/>
</svg>
</template>

<style scoped>
svg {
    height: 24px;
    width: auto;
}
</style>

<script>
export default {
    name: 'Logo',
};
</script>
