<template>
<svg width="50" height="50" viewBox="0 0 50 50" xmlns="http://www.w3.org/2000/svg">
    <rect x="0.513428" width="50" height="50" />
    <path d="M23.5134 17.929L30.5845 25L23.5134 32.0711" stroke="white" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
</svg>
</template>

<style>

</style>

<script>
export default {
    name: 'IconNext',
};
</script>
