<template>
    <section id="page-contact-us" :class="{'side-margins': !loading}">
        <div v-if="loading">
            <Loading />
        </div>
        <div v-else-if="content">
            <section id="block-contact-us" class="border-bottom-fw contact-info">
                <div>
                    <h1>{{ content.leadin }}</h1>
                    <p class="large pr-2">{{ content.overview }}</p>

                    <div class="items">
                        <div>
                            <h3 class="h5-compact">Inquiries</h3>
                            <p><a href="mailto:info@upswell.studio">info@upswell.studio</a><br />+1 503 660 3261</p>
                        </div>
                        <div>
                            <h3 class="h5-compact">Studio Location</h3>
                            <p>555 SE Martin Luther King Jr Blvd<br />Suite 520<br />Portland, Oregon 97214
                            </p>
                        </div>
                    </div>
                </div>
                <img :src="content.hero_image.asset.url" :alt="content.hero_image.asset.altText">
            </section>

            <!-- Jobs -->
            <section id="block-jobs" class="jobs-info">
                <img :src="content.jobs_image.asset.url" :alt="content.jobs_image.asset.altText" />
                <div>
                    <h1>{{ content.jobs_leadin }}</h1>
                    <VueMarkdown class="p-large">{{ content.jobs_overview }}</VueMarkdown>
                    <ul class="items mt-3">
                        <li v-for="job in content.jobs" :key="job._key">
                            <h3 class="h5-compact">
                                <a :href="job.linkedin_url" target="_blank">
                                    {{ job.title }}
                                </a>
                            </h3>
                            <p>{{ job.short_description }}</p>
                        </li>
                    </ul>
                </div>
            </section>

            <JsonLD :data="json_ld" />
        </div>
    </section>
</template>

<script>
import client from '../sanity'
import VueMarkdown from 'vue-markdown'

import JsonLD from '../components/JsonLD.vue'
import Loading from '../components/Loading.vue'

export default {
    components: {
        JsonLD,
        Loading,
        VueMarkdown
    },
    data() {
        return {
            'loading': false,
            'content': null
        }
    },
    computed: {
        json_ld() {
            return {
                "@context": "http://schema.org",
                "@type": "WebPage",
                name: this.title,
                description: this.description,
                url: window.location.href
            }
        }
    },
    metaInfo() {
        if (!this.content) return;
        return {
            title: this.content.title, 
            meta: [
                { name: 'description', content: this.content.description },
                { property: 'og:title', content: this.content.title, id: 'og-title' },
                { property: 'og:description', content: this.content.description, id: 'og-desc' },
                { property: 'og:image', content: this.content.image ? this.content.image.asset.url : '', id: 'og-image' }
            ]
        }
    },
    async mounted() {
        this.loading = true

        const query = `*[_type == "contact" && slug.current == "contact"][0]{
            title, description, image{asset->{url}},
            leadin, overview, hero_image{asset->{url, altText}},
            jobs_leadin, jobs_overview, jobs_image{asset->{url, altText}}, jobs
        }`
        
        client.fetch(query).then((results) => {
            this.content = results
            this.loading = false
        })
    }

}
</script>

<style lang="scss" scoped>
h1 {
    line-height: unset;
    margin-top: -1rem;
}

.h5-compact {
    margin-bottom: $s1;
}

section section {
    padding-top: $s4;
    padding-bottom: $s4;

    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;

    >div {
        width: 60%;
        padding-right: $s3;
        padding-bottom: $s3;
        box-sizing: border-box;
    }

    .items {
        display: flex;
        flex-wrap: wrap;
        padding-right: $s3;

        div,
        li {
            width: 50%;
            min-width: 300px;
            padding-right: $s2;
            margin-top: $s2;
            box-sizing: border-box;

            p {
                white-space: pre-wrap;
            }
        }

        @media (max-width: 767px) {
            div, li { 
                padding-right: 0;
            }
        }
    }

    img {
        height: 100%;
        width: 40%;
        margin-bottom: $s4;
    }

    &.jobs-info {
        padding-top: 0;
        padding-bottom: 0;

        >div {
            padding-left: $s3;
            padding-right: 0;
        }
    }

    @media (max-width: 1260px) {
        >div {
            padding-right: 0;
            width: 100%;
            height: 100%;
        }

        img {
            width: 100%;
        }


        &.jobs-info {
            flex-direction: column-reverse;

            >div {
                padding-left: 0;
                padding-right: 0;
            }
        }
    }
}
</style>