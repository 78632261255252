<template>
    <svg width="23" height="14" viewBox="0 0 23 14" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M22.6796 5.79666C21.038 5.58791 19.7048 4.88099 18.7227 3.69962C17.9351 2.74124 17.4275 1.50294 17.1997 0.0131836L15.2213 1.17083C15.3067 1.41754 15.4016 1.66425 15.5107 1.90147C15.895 2.73175 16.379 3.44816 16.9483 4.03647C16.9483 4.03647 18.9315 5.82513 19.8993 5.82988H0.684326V7.95539H19.9041C18.9362 7.95539 16.953 9.74879 16.953 9.74879C16.3837 10.3371 15.8998 11.0535 15.5155 11.8838C15.4063 12.1258 15.3115 12.3677 15.2261 12.6144L17.2045 13.7721C17.4275 12.2823 17.9351 11.044 18.7275 10.0856C19.7096 8.89953 21.0427 8.19735 22.6843 7.9886L22.6796 5.79666Z" />
    </svg>
</template>

<script>
export default {
    name: 'IconArrowRight',
};
</script>

<style lang="scss" scoped>
    svg {
        fill: #E6E8A3;
    }
</style>
