<template>
    <section id="page-services">
        <div v-if="loading">
            <Loading />
        </div>
        <div v-else-if="content">

            <!-- Heading -->
            <h1 class="hide">Services</h1>
            <p class="hide">{{ content.description }}</p>

            <!-- Block: Reel -->
            <section id="block-reel" class="diags">
                <div class="header-media">
                    <video :title="content.reel_title" autoplay controls muted loop playsinline>
                        <source :src="content.reel_url" type="video/mp4">
                    </video>
                </div>
            </section>

            <!-- Heading (Side) -->
            <h2 class="h5 label rotate hide-small" aria-hidden="true">Services</h2>

            <!-- Block: Services -->
            <div class="border-bottom-fw" aria-hidden="true"></div>
            <section id="block-services" class="services-wrapper cheater-margins cheater-margins-full borders">
                <div class="services-container d-flex">
                    <div v-for="entry in content.services" :key="entry.service">
                        <Value
                            :image="entry.icon_url"
                            :header="entry.service"
                            :text="entry.services" />
                        <div class="border-bottom-fw hide-desktop"></div>
                    </div>
                </div>
            </section>

            <!-- Block: Services Statement -->
            <section id="block-services-statement" class="impact-container d-flex cheater-margins borders">
                <div class="services-content">
                    <h3>{{ content.leadin }}</h3>
                    <VueMarkdown>{{ content.overview }}</VueMarkdown>
                </div>
                <div v-if="content.overview_image">
                    <!-- <div v-for="(image, index) in content.overview_image" :key="index"
                            class="layered-image">
                        <img :src="image.asset.url" :alt="image.asset.altText">
                    </div> -->
                </div>
            </section>

            <!-- Block: Testimonial -->
            <div class="border-bottom-fw hide-desktop" aria-hidden="true"></div>
            <section id="block-testimonials" class="quote-container cheater-margins borders no-bottom-border">
                <div aria-hidden="true" role="presentation"><IconQuote /></div>
                <transition-group name="fade">
                    <figure v-if="content.testimonials[counter]" :key="counter">
                        <blockquote class="h5-compact">{{ content.testimonials[counter].quotation }}</blockquote>
                        <cite>
                            {{ content.testimonials[counter].name }}, 
                            {{ content.testimonials[counter].position }}, {{ content.testimonials[counter].client.name }}
                        </cite>
                    </figure>
                </transition-group>
            </section>

            <!-- Block: Call to Action -->
            <hr role="presentation" aria-hidden="true" />
            <CallToAction
                 :header="content.cta"
                 buttonText="Get to know us"
                 buttonURL="/studio" />

            <JsonLD :data="json_ld" />
        </div>
    </section>
</template>


<script>
import client from '../sanity'
import VueMarkdown from 'vue-markdown'

import CallToAction from '../components/CallToAction.vue'
import JsonLD from '../components/JsonLD.vue'
import Loading from '../components/Loading.vue'
import Value from '../components/Value.vue'
import IconQuote from '../components/icons/IconQuote.vue'

export default {
    components: {
        CallToAction,
        JsonLD,
        Loading,
        IconQuote,
        Value,
        VueMarkdown
    },
    metaInfo() {
        if (!this.content) return;
        return {
            title: this.content.title, 
            meta: [
                { name: 'description', content: this.content.description },
                { property: 'og:title', content: this.content.title, id: 'og-title' },
                { property: 'og:description', content: this.content.description, id: 'og-desc' },
                { property: 'og:image', content: this.content.image ? this.content.image.asset.url : '', id: 'og-image' }
            ]
        }
    },
    data() {
        return {
            content: null,
            counter: 0,
            loading: false
        }
    },
    computed: {
        json_ld() {
            return {
                "@context": "http://schema.org",
                "@type": "WebPage",
                name: this.title,
                description: this.description,
                url: window.location.href
            }
        }
    },
    methods: {
        cycleQuotation() {
            let testimonials = this.content.testimonials;

            if(testimonials.length == 1) return;

            setTimeout(() => {
                if (this.counter < testimonials.length - 1) {
                    this.counter += 1;
                } else {
                    this.counter = 0;
                }
                this.cycleQuotation();
            }, 5000);
        }
    },
    async mounted() {
        if(this.page) {
            return
        }

        this.loading = true

        const query = `*[_type == "services" && slug.current == "services"][0]{
            title, description, image{asset->{url}},
            'reel_title': reel.title, 'reel_url': reel.asset->url,
            services[]{service, services, 'icon_url': image.asset->url},
            leadin, overview, overview_image[]{asset->{url, altText}},
            testimonials[]->{_id, quotation, name, position, client->{name}},
            cta
        }`
        
        client.fetch(query).then((results) => {
            this.content = results
            this.loading = false
            this.cycleQuotation()
        })
    }
}
</script>


<style lang='scss' scoped>

.label.rotate {
    margin-top: 1.2rem;
    margin-left: -0.5rem;
}

.header-media {
    align-items: center;
    display: flex;
    justify-content: center;
    padding: $s4;

    video, img {
        width: 95%;
        z-index: 1;
    }

    @media (max-width: 786px) {
        padding: $s3 $s1;
    }
}

.services-container {
    flex-direction: row;
    justify-content: center;
    padding: $s3;
    box-sizing: border-box;
    width: 100%;
    position: relative;

    div {
        width: 70%;
        margin: 0 auto;
    }

    &:after {
        content: '';
        position: absolute;
        bottom: -1px;
        right: -70px;
        width: 300px;
        border-bottom: 1px $upswell-medium-blue solid;
        height: 1px;
    }

    @media (max-width: 786px) {
        &:after {
            display: none;
        }
    }
}

.services-wrapper {
    border-right: none;

    
}

.impact-container {
    border-right: none;
    margin-right: 0;
    padding-left: $s2;
    padding-bottom: $s2;
    padding-top: $s2;

    @media (max-width: 786px) {
        padding-bottom: 0;
    }
}

.layered-images {
    align-items: center;
    justify-content: center;
    position: relative;
    width: 50%;

    .layered-image:nth-child(1) {
        border-radius: 50%;
        right: 25%;
        top: 30%;
        z-index: 3;
    }

    .layered-image:nth-child(2) {
        right: 32%;
        top: 20%;
        z-index: 2;
    }

    .layered-image:nth-child(3) {
        right: 40%;
        top: 10%;
        z-index: 1;
    }
}

.services-content {
    padding: $s2 0 $s2 $s3;
    width: 50%;
}

.layered-image {
    position: absolute;
    overflow: hidden;
    width: 250px;
    height: 250px;

    img {
        width: 100%;
        height: 100%;
        object-fit: cover;
    }
}

.quote-container {
    display: flex;
    margin-right: 0;
    padding: $s3 $s2;
    padding-right: 12rem;
    border-right: none;

    svg {
        margin: .5rem $s1 0 0;
    }

    blockquote, figure {
        margin: 0;
        padding: 0;
    }

    cite {
        display: block;
        margin-top: $s1;
    }

    @media (max-width: 786px) {
        margin-left: 0;
        padding: $s3 $s2;
    }
}

@media (max-width: 991px) {
    .impact-container {
        flex-direction: column;
    }

    .services-content {
        width: 80%;
    }

    .layered-images {
        height: 250px;
        width: 80%;

        .layered-image:nth-child(1) {
            right: 12%;
            top: 20%;
        }

        .layered-image:nth-child(2) {
            right: 25%;
            top: 8%;
        }

        .layered-image:nth-child(3) {
            right: 35%;
            top: 0%;
        }
    }
}

@media (max-width: 800px) {
    .services-container {
        flex-direction: column;
        padding: $s2 0;

        div {
            width: 100%;
        }
    }
}

@media (max-width: 767px) {
    .layered-images {
        left: 70px;
        width: 80%;
    }

    .impact-container {
        padding-left: 0;
    }

    .services-content {
        align-items: center;
        display: flex;
        flex-direction: column;
        padding: 0 0 $s5 0;
        // padding-right: 400px;
        width: 95%;
    }
}

.fade-enter-active {
    transition: all 1s ease-in-out;
}

.fade-enter, .fade-leave-to {
    opacity: 0;
}
</style>
