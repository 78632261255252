<template>
    <section id="page-projects">
        <div v-if="loading">
            <Loading />
        </div>
        <div v-else-if="content">
            <h1 class="h5 label rotate hide-small">Recent Work</h1>
            <section class="cheater-margins border-bottom-fw">
                <section class="no-bottom-border">

                    <!-- Block: Theme -->
                    <div class="toggles borders no-bottom-border">
                        <button v-for="theme in content.themes" :key="theme.slug.current"
                                class="toggle-button"
                                :class="{'selected': isFilterSelected('theme', theme.slug.current)}"
                                @click="onFilterClick('theme', theme.slug.current)">
                            {{ theme.title }}
                        </button>
                    </div>

                    <!-- Block: Projects -->
                    <section class="grid">
                        <div v-for="project in content.page.projects" :key="project.slug.current"
                                class="item" 
                                :class="{ 'full-width': isFullWidth(project), 'on-display': isProjectDisplayable(project) }">
                            <ProjectLink :project="project" />
                        </div>

                        <div class="item filler" aria-hidden="true"></div>
                        <div class="item filler" aria-hidden="true"></div>
                        <div class="item filler" aria-hidden="true"></div>
                        <div class="item filler" aria-hidden="true"></div>
                        <div class="item filler" aria-hidden="true"></div>
                        <div class="item filler" aria-hidden="true"></div>
                    </section>
                </section>
            </section>
            <CallToAction
                :header="content.page.cta"
                buttonText="Get the details"
                buttonURL="/services" />
            <JsonLD :data="json_ld" />
        </div>
    </section>
</template>

<script>
import client from '../sanity'

import CallToAction from '../components/CallToAction.vue'
import JsonLD from '../components/JsonLD.vue'
import Loading from '../components/Loading.vue'
import ProjectLink from '../components/ProjectLink.vue'

export default {
    components: {
        CallToAction,
        JsonLD,
        Loading,
        ProjectLink
    },
    data() {
        return {
            index: null,
            loading: false,
            content: null,
            selectedCategories: []
        }
    },
    computed: {
        json_ld() {
            if (!this.content) return;
            return {
                "@context": "http://schema.org",
                "@type": "WebPage",
                name: this.title,
                description: this.description,
                url: window.location.href
            }
        }
    },
    metaInfo() {
        if (!this.content) return;
        return {
            title: this.content.page.title,
            meta: [
                { name: 'description', content: this.content.page.description },
                { property: 'og:title', content: this.content.page.title, id: 'og-title' },
                { property: 'og:description', content: this.content.page.description, id: 'og-desc' },
                { property: 'og:image', content: this.content.page.image ? this.content.page.image.asset.url : '', id: 'og-image' }
            ]
        }
    },
    methods: {

        /**
         * Determines if the filter should be in the selected state based on the selected filter
         * in the route.
         * @param {*} filter 
         * @param {*} category 
         */
        isFilterSelected(filter, category) {
            if(filter in this.$route.query) {
                return this.$route.query[filter] == category;
            }
            return false;
        },

        /**
         * Determines is the project should display as full-width based on the display class
         * provided on the project record.
         * @param {*} project The Project record
         */
        isFullWidth(project) {
            return project.layout_work == 'full-width';
        },

        /**
         * Determines if the project is filtered and if it should display
         * @param {*} project The complete project JSON
         */
        isProjectDisplayable(project) {
            let theme = this.$route.query.theme || null

            if(!theme) return true;
            if(project.theme) {
                return project.theme.slug.current == theme
            }
            
            return false;
        },

        /**
         * Modify the router query state with the selected filter when a filter is selected. The
         * change in router state will be picked up by watch where the results will be filtered.
         * @param (*) filter The category/key to filter
         * @param {*} category The slug of the filter to select
         */
        onFilterClick(filter, category) {
            if(filter in this.$route.query) {
                if(this.$route.query[filter] == category) {
                    this.$router.replace({query: {}})
                    return;
                }
            }
            let query = {}
            query[filter] = category;
            this.$router.replace({query: query})
        },
    },
    async mounted() {
        this.loading = true

        const query = `{
            'page': *[_type == "projects"][0]{
                slug, title, description, image{asset->{url}}, cta, 
                projects[]-> {
                    _id, slug, title, client->{name},
                    theme->{slug}, project_types,
                    image{asset->{url, altText}}, preview{asset->{url, mimeType}},
                    image_wide{asset->{url, altText}}, preview_wide{asset->{url, mimeType}},
                    layout_work
                }
            },
            'themes': *[_type == "themes"]{
                _id, slug, title
            }
        }`
        
        client.fetch(query).then((results) => {
            this.content = results
            this.loading = false
        })
    }
}
</script>

<style lang="scss" scoped>
@media (max-width: 767px) {
    .cheater-margins {
        margin: 0;
    }
}

.label {
    font-size: 1rem;
    margin-left: -35px;
    margin-top: 50px;
}

.toggles {
    padding: $s4 $s2;
    display: flex;
    justify-content: center;
    flex-wrap: wrap;

    .toggle-button {
        font-size: 16px;
        margin: .5rem $s1;
        border-radius: 30px;
        padding: 1rem 1.5rem;
        border: 1px solid $upswell-medium-blue;
        background-color: $upswell-blue;
        color: $upswell-white;
        outline: none;
        transition: 0.4s;

        &:hover,
        &.selected {
            background-color: $upswell-white;
            color: $upswell-blue;
        }

        &:hover {
            cursor: pointer;
        }
    }

    @media (max-width: 1080px) {
        padding: $s2 0;

        .toggle-button {
            font-size: 1rem;
            margin: .5rem .25rem;
            padding: 1rem 1rem;
        }
    }
}

.grid {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(420px, 1fr));
    grid-auto-flow: dense;
    grid-column-gap: 0px;
    grid-row-gap: 0px;
    background-color: $upswell-medium-blue;
    border: 1px solid $upswell-medium-blue;
    border-bottom: 0;
    border-right: 0;


    @media (max-width: 990px) {
        grid-template-columns: repeat(auto-fit, minmax(260px, 1fr));
    }

    .item {
        background-color: $upswell-blue;
        border-bottom: 1px solid $upswell-medium-blue;
        border-right: 1px solid $upswell-medium-blue;
        min-width: 0;
        max-width: 100%;
        display: none;

        &.on-display,
        &.filler {
            display: block;
        }

        &.full-width {
            grid-column: 1 / -1;

            @media (min-width: 1600px) {
                grid-column: 1 / span 3;
            }
        }

        .medium-link {
            padding: $s3;

            p {
                margin-top: 0;

                &.h5 {
                    margin-bottom: $s1;
                }
            }

            span {
                margin-right: $s1;
            }

            a {
                text-decoration: none;
            }

        }
    }

    .item:nth-last-child(-n+6) {
        border-bottom: 0;
    }

    
    @media (max-width: 768px) {
        border-left: none;

        //  ---- attempt to apply/remove border on elements 
        //  depending on if they are on the left or right of the screen
        // the full-width items prevent targeting all even/odd

        // .full-width:nth-child(odd) ~ .item:nth-child(odd),
        // .item {
        //     border-right: none;
            
        //     outline: dotted 2px red;
        //     outline-offset: -5px;
        // }

        // .full-width:nth-child(odd) ~ .item:nth-child(even),
        // .item:nth-child(odd) { 
        //     border-right: solid 1px $upswell-light-blue; 
            
        //     outline-offset: -5px;
        //     outline: dashed 2px green;
        // }

        // .full-width:nth-child(even) ~ .item:nth-child(even) {
        //     border-right: solid 1px $upswell-light-blue; 

        //     outline-offset: -5px;
        //     outline: dashed 2px green;
        // }

        // .full-width:nth-child(even) ~ .item:nth-child(odd) {
        //     border-right: none;

        //     outline-offset: -5px;
        //     outline: dotted 2px red;
        // }
    }

    @media (max-width: 520px) {
        .item { border-right: none; }
    }
}

.border-bottom-fw {
    &:after {
        margin-top: -1px;
    }
}
</style>