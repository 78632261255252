<template>
    <div class="vimeo-block">
        <div class="wrapper">
            <!-- //NOSONAR --><iframe :src="`https://player.vimeo.com/video/${element.vimeo_id}?h=4cee4a6d48&amp;badge=0&amp;autopause=0&amp;player_id=0&amp;app_id=58479`" 
                frameborder="0" 
                allow="autoplay; fullscreen; picture-in-picture" 
                allowfullscreen 
                class="video-embed" 
                :title="element.title">
            </iframe>
        </div>
    </div>
</template>

<script>
export default {
    name: 'VimeoBlock',
    props: {
        element: Object
    }
}
</script>

<style lang="scss" scoped>
.vimeo-block {
    width: 100%;
    margin: 0 0 $s3 0;

    .video-embed {
        height: 100%;
        left: 0;
        position: absolute; 
        top: 0;
        width: 100%; 
    }

    .wrapper {
        padding: 56.25% 0 0 0;
        position: relative;
    }
}

@media (max-width: 900px) {
    .vimeo-block {
        padding: $s2 0;
    }
}
</style>