<template>
    <section id="page-project">
        <div v-if="loading">
            <Loading />
        </div>
        <div v-else-if="content">
            <!-- project info -->
            <article id="page-project" class="side-margins fw-mobile">

                <!-- Block: Header -->
                <header id="block-header" class="no-bottom-border border-bottom-fw">
                    <div class="header-content">
                        <router-link :to="{ name: 'projects' }" class="nav-link hide-small">
                            <span class="sr-only">See recent work</span>
                            <IconArrowLeft />
                        </router-link>

                        <h1>{{ content.title }}</h1>
                        <p class="large">{{ content.description }}</p>
                    </div>
                    <div v-if="content.hero" class="image-container">
                        <img :src="content.hero.asset.url" :alt="content.hero.asset.altText">
                    </div>
                </header>

                <!-- Block: Metadata -->
                <aside class="border-bottom-fw">
                    <h2 class="sr-only">Project Information</h2>
                    <dl class="project-info" :class="{ 'has-link': content.link }">
                        <div v-if="content.client">
                            <dt>Client</dt>
                            <dd v-if="content.client.name">{{ content.client.name }}</dd>
                        </div>
                        <div v-if="content.location">
                            <dt>Location</dt>
                            <dd>{{ content.location }}</dd>
                        </div>
                        <div v-if="content.year">
                            <dt>Year</dt>
                            <dd>{{ content.year }}</dd>
                        </div>
                        <div v-if="content.services && content.services[0].fields">
                            <dt>Services</dt>
                            <dd v-for="s in content.services" :key="s.id" class="multiple-items">
                                <span v-if="s.fields">{{ s.fields.serviceName }}</span>
                            </dd>
                        </div>
                    </dl>

                    <div v-if="content.url" class="button-container">
                        <Button text="Visit Site" :link="content.url" :external="true" />
                    </div>
                </aside>

                <!-- Block: Body -->
                <section class="project-content-container borders">

                    <!-- Block: Body -->
                    <div v-for="element in content.body" :key="element._key">
                        <TextBlock v-if="element._type === 'overview'" :element="element" />
                        <RichtextBlock v-if="element._type === 'bodyText'" :element="element" />
                        <ImageOne v-else-if="element._type === 'one_up'" :element="element" />
                        <ImageTwo v-else-if="element._type === 'two_up'" :element="element" />
                        <Carousel v-else-if="element._type === 'slideshow'" :items="content.slideshow" />
                        <PullQuote v-else-if="element._type === 'pull_quote'" :element="element" />
                        <Vimeo v-else-if="element._type === 'vimeo'" :element="element" />
                        <div v-else-if="isNotHandledType(element._type)" :key="element._key">
                            <pre>{{ element }}</pre>
                        </div>
                    </div>

                    <!-- Block: Partners & Awards -->
                    <div class="partner-awards-container">
                        <div class="partner-awards-block">
                            <div v-if="content.partners" class="partners">
                                <div v-if="content.partners.length > 0">
                                    <h4 class="label-white">Partners</h4>
                                    <ul v-for="partner in content.partners" :key="partner._key" class="team-card">
                                        <li>{{ partner.partner }}</li>
                                    </ul>
                                </div>
                            </div>
                            <div v-if="content.recognition" class="awards">
                                <div v-if="content.recognition.length > 0">
                                    <h4 class="label-white">Recognition</h4>
                                    <ul>
                                        <li v-for="recognition in content.recognition" :key="recognition._id">
                                            {{ recognition.by }} {{ recognition.title }}, {{ recognition.year }}
                                        </li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
            </article>

            <!-- Block: Related Projects -->
            <section v-if="content.related" class="related-projects diag-bottom">
                <div class="side-margins">
                    <h4>Related Projects</h4>
                    <ul>
                        <li v-for="(project, index) in content.related" :key="index">
                            <ProjectLink :project="project" />
                        </li>
                    </ul>
                </div>
            </section>
            <JsonLD :data="json_ld" />
        </div>
    </section>
</template>

<script>
import client from '../sanity'

import IconArrowLeft from '../components/icons/IconArrowLeft.vue'

import Button from '../components/Button.vue'
import JsonLD from '../components/JsonLD.vue'
import Loading from '../components/Loading.vue'
import ProjectLink from '../components/ProjectLink.vue'

import Carousel from '../components/project/Carousel'
import ImageOne from '../components/project/ImageOne.vue'
import ImageTwo from '../components/project/ImageTwo.vue'
import PullQuote from '../components/project/PullQuote.vue'
import TextBlock from '../components/project/TextBlock.vue'
import RichtextBlock from '../components/project/RichtextBlock.vue'
import Vimeo from '../components/project/Vimeo.vue'

export default {
    components: {
        Button,
        Carousel,
        JsonLD,
        IconArrowLeft,
        ImageOne,
        ImageTwo,
        Loading,
        ProjectLink,
        PullQuote,
        TextBlock,
        RichtextBlock,
        Vimeo
    },
    data() {
        return {
            index: null,
            content: null,
            loading: false
        }
    },
    computed: {
        json_ld() {
            return {
                "@context": "http://schema.org",
                "@type": "WebPage",
                name: this.content.title,
                description: this.content.description,
                url: window.location.href
            }
        }
    },
    methods: {
        isNotHandledType(type) {
            return !['overview', 'bodyText', 'one_up', 'two_up', 'slideshow', 'pull_quote', 'vimeo'].includes(type);
        },
        fetch(slug) {
            console.log(`Fetch: ${slug}`)
            this.loading = true

            const query = `*[_type == "project" && slug.current == "${slug}"][0]{
                slug, title, description, year, location, url, client->{name}, hero{asset->{url, altText}},
                description,
                body[]{_key, _type, title, text, bodyText, one{asset->{url, description, altText}}, two{asset->{url, description, altText}}, vimeo_id},
                related[]->{slug, title, client->{name}, image{asset->{url, altText}}, preview{asset->{url, mimeType}}},
                slideshow[]{asset->{url, altText, description}},
                partners,  recognition[]->{_id, title, by, year}
            }`

            client.fetch(query).then((results) => {
                if (!results) {
                    this.$router.push({ 'name': 'not-found' })
                }

                this.content = results
                this.loading = false
            })
        }
    },
    metaInfo() {
        if (!this.content) return;
        return {
            title: this.content.title,
            titleTemplate: '%s | Upswell',
            meta: [
                { name: 'description', content: this.content.description },
                { property: 'og:title', content: this.content.title, id: 'og-title' },
                { property: 'og:description', content: this.content.description, id: 'og-desc' },
                { property: 'og:image', content: this.content.hero.asset.url, id: 'og-image' }
            ]
        }
    },
    async mounted() {
        this.fetch(this.$route.params.slug)
    },
    watch: {
        $route(to, from) {
            this.fetch(this.$route.params.slug)
        }
    }
}
</script>

<style lang="scss" scoped>
.left {
    grid-column: 1;
}

.right {
    grid-column: 2;
}

header {
    display: flex;

    .header-content {
        padding: $s4;
        padding-left: 0;
        flex-grow: 2;
        color: $upswell-white;

        h1 {
            line-height: 3.65rem;
            margin-top: $s3;
            margin-bottom: 0;
        }

        p {
            max-width: 600px;
            margin-top: $s1;
        }
    }

    .image-container {
        height: 38vw;
        width: 38vw;
        flex-shrink: 0;

        img {
            object-fit: cover;
            height: 100%;
            width: 100%;
        }
    }
}

aside {
    $button-width: 150px;
    $button-margin: $s2;

    display: flex;
    min-height: 150px;

    .project-info {
        padding-top: .5rem;
        flex-grow: 3;
        display: flex;
        justify-content: flex-start;
        margin-right: calc(#{$button-width} + #{$button-margin});

        div {
            width: 25%;
            padding: 0 1rem;
            box-sizing: border-box;

            dt {
                letter-spacing: 1.3px;
                color: $upswell-light-blue;
                margin-bottom: .65rem;
            }

            dd {
                color: $upswell-white;
                margin-left: 0;
                line-height: 2rem;

                &.multiple-items {
                    display: inline;

                    &:not(:last-child) {
                        &:after {
                            content: ', ';
                        }
                    }
                }
            }
        }

        &.has-link {
            margin-right: 0;
        }
    }

    .button-container {
        height: $button-width;
        width: $button-width;
        border-left: solid 1px $upswell-medium-blue;
        border-right: solid 1px $upswell-medium-blue;
        margin-left: $button-margin;
    }
}


.awards {
    border-top: 1px solid $upswell-medium-blue;
    width: 60%;
    padding-top: $s4;

    h4 {
        padding: 0;
    }

    p {
        width: 90%;
    }

    @media (max-width: 48em) {
        width: 100%;
    }
}

.partners {
    border-top: 1px solid $upswell-medium-blue;
    width: 40%;
    padding-top: $s4;

    h4 {
        padding: 0;
    }

    @media (max-width: 48em) {
        width: 100%;
    }
}

.partner-awards-container {
    display: grid;
    grid-template-columns: 35% 65%;

    ul {
        font-size: 1rem;
        line-height: 2rem;
    }

    @media (max-width: 48em) {
        display: block;
    }
}

.partner-awards-block {
    border-left: 1px solid $upswell-medium-blue;
    display: flex;
    grid-column: 2;
    padding: 0 $s2 $s2;

    @media (max-width: 48em) {
        border: none;
        display: block;
        padding: 0;
    }
}

.project-content-container {
    border-bottom: 0;
    margin-top: $s5;
    padding: 0 $s1 0 $s1;

    @media (max-width: 48em) {
        margin: 0;
        padding: 0;
    }

    .label-white {
        line-height: 1rem;
    }
}

.related-projects {
    border-top: 1px solid $upswell-medium-blue;
    padding: $s2 0;
    padding-top: 5rem;

    div ul {
        display: flex;
        margin-top: -3rem;
        margin-left: -3rem;

        li {
            min-width: 350px;
            width: 450px;
        }

        @media (max-width: 1100px) {
            flex-wrap: wrap;

            li {
                width: 300px;
            }
        }
    }

    @media (max-width: 48em) {
        .side-margins {
            margin: 0;
        }

        div {
            padding-left: 1.5rem;
            padding-right: 1.5rem;

            ul {
                display: block;
                margin-left: -1.5rem;
                margin-right: -1.5rem;

                li {
                    width: 100%;
                }
            }
        }
    }
}


@media (max-width: 1260px) {
    header {
        align-items: center;

        .header-content {
            padding: 1.5rem;
            padding-left: 0;
        }
    }

    aside {
        .project-info {
            margin-right: 0;
        }
    }
}

@media (max-width: 900px) {
    header {
        flex-direction: column-reverse;

        .image-container {
            padding-top: $s3;
            height: 74vw;
            width: 74vw;
        }
    }

    aside {
        flex-direction: column-reverse; // column breaks border

        .project-info {
            flex-wrap: wrap;
            order: 2;
            padding-bottom: $s2;

            div {
                margin-top: 1.75rem;
                width: 100%;
                padding-left: 0;
            }
        }

        .button-container {
            order: 1;
            height: 150px;
            width: 150px;
            border-left: none;
            border-right: none;
            margin: $s2 auto $s3;
        }
    }

    .project-content-container {
        margin: $s2 0;
    }
}

@media (max-width: 767px) {
    header {
        .header-content {

            padding: $s2;
            padding-left: 0;

            h1 {
                font-size: 2.6rem;
                line-height: 3.25rem;
                margin-top: 0;
            }
        }

        .image-container {
            padding-top: 0;
            height: 100vw;
            width: 100vw;
        }
    }

    .side-margins.fw-mobile {
        margin-left: 0;
        margin-right: 0;

        aside,
        section,
        header .header-content {
            margin-left: 1.5rem;
            margin-right: 1.5rem;
        }
    }
}
</style>