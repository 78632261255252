<template>
<div class="hamburger">
    <hr>
    <hr>
    <hr>
</div>
</template>

<style lang='scss'>
.hamburger {
    width: 24px;
    hr {
        margin: 6px 0;
        border-top: 0;
        border-left: 0;
        border-right: 0;
        border-bottom: 1.2px solid $upswell-light-blue;
    }
}
</style>

<script>
export default {
    name: 'IconHamburger',
};
</script>
