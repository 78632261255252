<template>
    <section id="page-studio" class="rotate-label-wrapper">
        <div v-if="loading">
            <Loading />
        </div>
        <div v-else-if="content">

            <!-- Heading -->
            <h1 id="studio-label" class="h5 label rotate hide-small">Our Studio</h1>

            <!-- Block: Leadin -->
            <section class="about-header cheater-margins borders">
                <div v-if="content.hero_images.length > 0">
                    <div v-for="image in content.hero_images" :key="image._key" class="layered-image">
                        <img :src="image.url" alt="" role="presentation">
                    </div>
                </div>
                <div class="text-layer">
                    <h2 class="h3">{{ content.leadin }}</h2>
                    <p class="large">{{ content.overview }}</p>
                </div>
            </section>

            <!-- Block: Values -->
            <h2 id="values-label" class="h5 label rotate hide-small">Our Values</h2>

            <div class="border-bottom-fw hide-desktop" aria-hidden="true"></div>
            <section class="cheater-margins borders">
                <div class="value-container d-flex">
                    <div v-for="value in content.values" :key="value.value">
                        <Value
                            :image="value.icon_url"
                            :header="value.value"
                            :text="value.values" />
                        <div class="border-bottom-fw hide-desktop"></div>
                    </div>
                </div>
            </section>

            <!-- Block: Team -->
            <h2 id="team-label" class="h5 label rotate hide-small">Our Team</h2>
            <section class="cheater-margins borders">
                <div class="team-container desktop-leadin">
                    <div class="team-header">
                        <h3 class="h5">{{ content.team_overview.split(' ').slice(0, 8).join(' ') }}</h3>
                    </div>
                    <div class="team-header">
                        <h3 class="h5">{{ content.team_overview.split(' ').slice(8, 16).join(' ') }}</h3>
                    </div>
                    <div class="team-header">
                        <h3 class="h5">{{ content.team_overview.split(' ').slice(16).join(' ') }}</h3>
                    </div>
                </div>
                <div class="team-container mobile-leadin">
                    <div class="team-header">
                        <h3 class="h5">{{ content.team_overview.split(' ').slice(0, 4).join(' ') }}</h3>
                    </div>
                    <div class="team-header">
                        <h3 class="h5">{{ content.team_overview.split(' ').slice(4, 7).join(' ') }}</h3>
                    </div>
                    <div class="team-header">
                        <h3 class="h5">{{ content.team_overview.split(' ').slice(7, 10).join(' ') }}</h3>
                    </div>
                    <div class="team-header">
                        <h3 class="h5">{{ content.team_overview.split(' ').slice(10, 14).join(' ') }}</h3>
                    </div>
                    <div class="team-header">
                        <h3 class="h5">{{ content.team_overview.split(' ').slice(14).join(' ') }}</h3>
                    </div>
                </div>
                <div class="team-section">
                    <h5>Team</h5>
                    <div class="team-cards-container">
                        <div v-for="member in content.team" :key="member._key" class="team-card">
                            <a :href="member.linkedin" target="_blank">
                                <strong class="team-memeber-name">{{ member.name }}</strong>
                            </a><br />
                            {{ member.title }}
                        </div>
                    </div>
                </div>
            </section>

            <!-- Block: Awards/Press -->
            <div class="border-bottom-fw hide-desktop" aria-hidden="true" role="presentation" />
            <section id="block-awards-press" class="awards-press-container d-flex cheater-margins borders no-bottom-border">
                <section id="block-recognition" class="recognition-container py-3">
                    <h4 class="h3">{{ content.recognition_overview }}</h4>
                    <VueMarkdown>{{ content.recognition }}</VueMarkdown>
                </section>
                <div class="border-bottom-fw hide-desktop" aria-hidden="true" role="presentation" />
                <section id="block-press" class="press-container py-3 pl-2">
                    <h4>{{ content.press_overview }}</h4>
                    <p v-for="(press, index) in content.press" :key="index">
                        <a v-if="press.link" :href="press.link" target="_blank">{{ press.title }}</a>
                        <span v-else>{{ press.title }}</span><br />
                        {{ press.publication }}
                    </p>
                </section>
            </section>
            
            <!-- Block: Call to Action -->
            <hr role="presentation" aria-hidden="true" />
            <CallToAction
                 :header="content.cta"
                 buttonText="Get in touch"
                 buttonURL="/contact" />

            <JsonLD :data="json_ld" />
        </div>
    </section>
</template>

<script>
import client from '../sanity'
import VueMarkdown from 'vue-markdown'

import CallToAction from '../components/CallToAction.vue'
import JsonLD from '../components/JsonLD.vue'
import Loading from '../components/Loading.vue'
import Value from '../components/Value.vue'


export default {
    components: {
        CallToAction,
        JsonLD,
        Loading,
        Value,
        VueMarkdown
    },
    data() {
        return {
            content: null,
            loading: false
        }
    },
    computed: {
        json_ld() {
            return {
                "@context": "http://schema.org",
                "@type": "WebPage",
                name: this.title,
                description: this.description,
                url: window.location.href
            }
        }
    },
    metaInfo() {
        if (!this.content) return;
        return {
            title: this.content.title, 
            meta: [
                { name: 'description', content: this.content.description },
                { property: 'og:title', content: this.content.title, id: 'og-title' },
                { property: 'og:description', content: this.content.description, id: 'og-desc' },
                { property: 'og:image', content: this.content.image ? this.content.image.asset.url : '', id: 'og-image' }
            ]
        }
    },
    async mounted() {
        if(this.page) {
            return
        }

        this.loading = true

        const query = `*[_type == "studio" && slug.current == "studio"][0]{
            title, description, image{asset->{url}},
            leadin, overview, hero_images[]{'url': asset->url},
            values[]{'icon_url': image.asset->url, value, values},
            team_overview, team,
            recognition_overview, recognition,
            press_overview, press[]->{title, publication, link},
            cta
        }`
        
        client.fetch(query).then((results) => {
            this.content = results
            this.loading = false
        })
    }

}
</script>

<style scoped lang='scss'>

#studio-label {
    margin-top: 2rem;
    margin-left: -1.5rem;
}

#values-label {
    margin-left: -26px;
}

#team-label {
    margin-left: -15px;
}

.about-header {
    height: 600px;
    padding-top: $s4;
    position: relative;

    .layered-image {
        position: absolute;
        width: 70%;
        height: 55%;
        overflow: hidden;

        img {
            width: 100%;
            height: auto;
        }
    }

    .layered-image:nth-child(1) {
        right: 5%;
        z-index: 1;
    }

    .layered-image:nth-child(2) {
        right: 9%;
        top: 14%;
        z-index: 2;
    }

    .layered-image:nth-child(3) {
        right: 14%;
        top: 19%;
        z-index: 3;
    }

    // -- Disabled pending animation treatment (KL, 2022-09-25)
    // .layered-image:hover {
    //     animation: fade-in .5s linear;
    //     z-index: 5;
    // }

    @media (max-width: 786px) {
        height: 720px;
        padding: $s2 0 0 0;

        .layered-image {
            width: 80%;
            height: 45%;
        }

        .layered-image:nth-child(1) {
            right: 0px;
        }

        .layered-image:nth-child(2) {
            right: 4%;
            top: 7%;
        }

        .layered-image:nth-child(3) {
            right: 8%;
            top: 11%;
        }

        .layered-image:hover {
            animation: fade-in .5s linear;
        }
    }
}

.press-container {
    width: 70%;
}

.recognition-container {
    border-right: 1px solid $upswell-medium-blue;
    padding-left: $s3;
    padding-right: $s3;
    width: 30%;
}

.text-layer {
    background: $upswell-blue;
    height: 400px;
    padding-right: $s4;
    position: absolute;
    right: 19%;
    top: 24%;
    width: 70%;
    z-index: 4;

    h2 {
        // border-top: 1px solid $upswell-medium-blue;
        margin-top: $s3;
        padding-top: $s2;
    }

    @media (max-width: 786px) {
        padding-right: 8%;
        padding-bottom: $s2;
        right: 12%;
        top: 15%;
        width: 80%;

        h2 {
            border-top: 1px solid $upswell-medium-blue;
            font-size: 28px;
            line-height: 32px;
            margin-top: $s2;
            padding-top: $s1;
        }
    }
}

.team-container {

    &.mobile-leadin {
        display: none;
    }

    .team-header {
        border-bottom: 1px solid $upswell-medium-blue;
        padding-left: $s4;

        h3 {
            font-size: 2.5vw;
            line-height: 4vh;
            padding-top: 2.5vh;
        }
    }

    .team-header:nth-child(3) {
        border: 0;
    }
}

.team-section {
    padding-left: $s4;
    justify-content: center;
    margin-bottom: $s4;

    .team-cards-container {
        display: flex;
        flex-wrap: wrap;
        line-height: 1.5rem;
        justify-content: space-between;
        width: 95%;
    }

    .team-card {
        margin-bottom: 1.2rem;
        width: 30%;

        a {
            font-size: 1.2rem;
            text-decoration: none;
        }

        a:hover {
            text-decoration: underline;
            cursor: pointer;
        }
    }
}

.team-info {
    box-sizing: content-box;
    background: $upswell-yellow;
    color: $upswell-blue;
    height: 100%;
    left: 0;
    opacity: 0;
    position: absolute;
    text-align: center;
    top: 0;
    width: calc(100% - 5px);
}

.team-info:hover {
    animation: fade-in .3s linear forwards;
    cursor: pointer;
}

.value-container {
    flex-direction: row;
    justify-content: center;
    padding: $s3;
    box-sizing: border-box;
    width: 100%;

    div {
        width: 70%;
        margin: 0 auto;
    }
}

@keyframes fade-in {
    from {
        opacity: 0
    }

    to {
        opacity: 1
    }
}

@media (max-width: 800px) {
    .value-container .hide-desktop {
        display: none;
    }
}
@media (max-width: 767px) {

    .value-container {
        align-items: center;
        flex-direction: column;
        padding: 0;

        div {
            width: 100%;
        }

        .hide-desktop {
            display: block;
        }
    }

    .team-container {
        padding: 0;
        padding-bottom: $s2;
        margin-left: -1.5rem;
        margin-right: -1.5rem;
        
        &.desktop-leadin {
            display: none;
        }
        &.mobile-leadin {
            display: block;
        }
        .team-header {
            padding-left: 1.5rem;
            padding-right: 1.5rem;

            &:nth-child(3) {
                border-bottom: 1px solid $upswell-medium-blue;
            }

            h3 {
                font-size: 1.25rem;
                line-height: 3rem;
                padding: 0;
                margin: 0;
            }
        }
    }


    .team-section {
        padding-left: 0;
    }

    .team-cards-container {
        flex-direction: column;
        flex-wrap: nowrap;

        .team-card {
            width: 100%;
        }
    }


    .team-info {
        width: 100%;
    }

    .awards-press-container {
        flex-direction: column;
    }

    .recognition-container {
        border-right: none;
        padding-left: 0;
        padding-right: 0;
        width: 100%;
    }

    .press-container {
        padding-left: 0;
        width: 100%;
    }

    .cta-container {
        margin: 0;
        padding: $s2 0;
        flex-direction: column;
        align-items: center;

        h5 {
            padding: 0 0 $s3 0;
            margin: 0;
            width: 100%;
        }
    }
}
</style>