<template>
    <figure class="image-block-one">
        <img :src="imageUrl(element.one)" :alt="element.one.asset.altText">
        <caption v-if="element.one.asset.description">{{ element.one.asset.description }}</caption>
    </figure>
</template>

<script>
import { sanityImageURL } from '../../lib/image';

export default {
    name: 'ImageOne',
    props: {
        element: Object
    },
    methods: {
        imageUrl(asset) {
            return sanityImageURL(asset).width(2400).url()
        }
    }
}
</script>

<style lang="scss" scoped>
.image-block-one {

    width: 100%;

    .middle-line {
        grid-column-start: 2;
        border-left: 1px solid $upswell-medium-blue;
    }

    caption {
        display: block;
        text-align: left;
        font-size: $small-body;
        margin-top: .5rem;
        line-height: 1.5rem;
    }

    @media (max-width: 48em) {
        border: none;
        // margin: 0;

        figure {
            margin: 0;
        }
    }
}
</style>