<template>
    <div class="text-block">
        <div class="left">
            <h2 class="label-white">{{ element.title }}</h2>
        </div>
        <div class="text-block-text right" v-html="text" ref="textBlockRef"></div>
    </div>
</template>

<script>
import { toHTML } from '@portabletext/to-html'

export default {
    name: 'RichtextBlock',
    props: {
        element: Object
    },
    computed: {
        text() {
            return this.element.text ? toHTML(this.element.text) : '';
        }
    },
    mounted() {
        if (this.$refs.textBlockRef) {
            const anchorTags = this.$refs.textBlockRef.querySelectorAll('a');
            anchorTags.forEach((aTag) => {
                aTag.setAttribute('target', '_blank');
                aTag.classList.add('link-tag');
            });
        }
    },
}
</script>

<style lang="scss" scoped>
.text-block {
    display: grid;
    grid-template-columns: 35% 65%;

    .label-white {
        margin-bottom: 4rem;
        padding-top: $s3;

        @media (max-width: 48em) {
            margin: 0;
            padding: 0;
        }
    }

    .text-block-text {
        border-left: 1px solid $upswell-medium-blue;
        padding: $s3 $s2;

        p {
            margin-top: -9px;
        }

        @media (max-width: 48em) {
            border: none;
            padding: 0 0;
        }
    }

    h2 {
        margin: 0;
        padding: 0 $s3 0 0;
        text-align: right;

        @media (max-width: 48em) {
            text-align: left;
        }
    }

    figure {
        margin: $s1 $s1 0 0;

        caption {
            display: block;
            text-align: left;
            font-size: $small-body;
            margin-top: .5rem;
            line-height: 1.5rem;
        }
    }

    @media (max-width: 48em) {
        border: none;
        display: block;

        figure {
            width: 100%;
            margin: 0;
            padding: $s2 0;
        }
    }

    &:first-child {

        .text-block-text,
        .label-white {
            padding-top: 0;
        }
    }
}
</style>