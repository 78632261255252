import Vue from 'vue'
import VueMeta from 'vue-meta'
import App from './App.vue'
import router from './router'
import Vue2TouchEvents from 'vue2-touch-events'
import ssrCarouselCss from 'vue-ssr-carousel/index.css'

require('@/assets/styles/global.scss')

Vue.use(Vue2TouchEvents)
Vue.use(VueMeta, {
    refreshOnceOnNavigation: true
})

// Setup Application
Vue.config.productionTip = false

new Vue({
    router,
    render: h => h(App),
}).$mount('#app')
