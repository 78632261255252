<template>
<svg width="36" height="32" viewBox="0 0 36 32" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M14.0157 17.7998L7.73592 17.7998C7.73592 10.5539 8.38 8.46068 14.0157 7.97762V1.37581C2.42227 1.37581 1.13411 7.65558 1.13411 17.6388V30.6814H14.0157V17.7998ZM34.9482 17.7998H28.6685C28.6685 10.5539 29.3126 8.46068 34.9482 7.97762L34.9482 1.37581C23.3548 1.37581 22.0667 7.65558 22.0667 17.6388V30.6814H34.9482V17.7998Z" stroke="#606E82"/>
</svg>
</template>

<script>
export default {
    name: 'IconQuote',
};
</script>
