import { render, staticRenderFns } from "./Vimeo.vue?vue&type=template&id=58b0e3b8&scoped=true&"
import script from "./Vimeo.vue?vue&type=script&lang=js&"
export * from "./Vimeo.vue?vue&type=script&lang=js&"
import style0 from "./Vimeo.vue?vue&type=style&index=0&id=58b0e3b8&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "58b0e3b8",
  null
  
)

export default component.exports