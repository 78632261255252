<template>
    <script v-if="data" type="application/ld+json">
    [
        {
            "@context": "https://schema.org",
            "@type": "Organization",
            "name": "Upswell",
            "address": {
                "@type": "PostalAddress",
                "streetAddress": "555 SE Martin Luther King Jr. Blvd",
                "addressLocality": "Portland, Oregon",
                "addressRegion": "Oregon",
                "postalCode": "97214",
                "addressCountry": "US"
            },
            "email": "info@hello-upswell.com",
            "telephone": "+1 971 244 0142"
        },
        {
            "@context": "http://schema.org",
            "@type": "WebSite",
            "name": "Upswell",
            "url": "https://www.upswell.studio/",
            "description": "Upswell creates experiences that activate new ways of seeing, understanding, and engaging with the urgent topics of our time.",
            "publisher": {
                "@context": "https://schema.org",
                "@type": "Organization",
                "name": "Upswell"
            },
            "mainEntity": {{ getEntity }}
        }
    ]
    </script>
</template>

<script>
export default {
    props: {
        'data': Object
    },
    computed: {
        getEntity() {
            return JSON.stringify(this.data) ? this.data : {}
        }
    }
}
</script>
