<template>
    <section>
        <TestPattern />
    </section>
</template>

<script>
import TestPattern from '../components/TestPattern.vue';

export default {
    components: {
        TestPattern
    },
    metaInfo() {
        return {
            title: 'Page Not Found', meta: [
                { name: 'description', content: 'Page not found page' },
                { property: 'og:title', content: 'Page Not Found', id: 'og-title' },
                { property: 'og:description', content: 'Page not found page', id: 'og-desc' },
            ]
        }
    },
    computed: {

    }
}
</script>