<template>
    <ssr-carousel class="carousel" :slides-per-page='1' :gutter='10' loop peek-left='70' peek-right='70' show-arrows
        :responsive='[
            {
                maxWidth: 787,
                peekLeft: 20,
                peekRight: 20,
            }]'>
        <template>
            <div v-for="slide in items" :key="slide._key" ref="slide" class="slide">
                <figure>
                    <img :src="imageUrl(slide)" :alt="slide.asset.altText">
                    <caption>{{ slide.asset.description }}</caption>
                </figure>
            </div>
        </template>

        <template #back-arrow='{ disabled }'>
            <IconPrevious :disabled="disabled" />
        </template>

        <template #next-arrow='{ disabled }'>
            <IconNext :disabled="disabled" />
        </template>
    </ssr-carousel>
</template>

<script>
import { sanityImageURL } from '../../lib/image';
import SsrCarousel from 'vue-ssr-carousel'

import IconPrevious from '../icons/IconPrevious';
import IconNext from '../icons/IconNext';

export default {
    props: {
        'items': Array
    },
    components: {
        SsrCarousel,
        IconPrevious,
        IconNext,
    },
    methods: {
        imageUrl(asset) {
            return sanityImageURL(asset).width(2400).height(1014).crop('center').fit('crop').url();
        }
    }
}

</script>

<style lang="scss" scoped>
.carousel {
    @media (max-width: 48em) {
        order: -1;
    }

    button {
        background: transparent;
        border: none;
        cursor: pointer;
        position: relative;

        svg {
            fill: transparent;
        }
    }

    .prev {
        display: flex;
        flex-direction: column;
        justify-content: center;
        margin-right: $s1;

        button {
            left: 10%;

            @media (max-width: 48em) {
                position: absolute;
                left: -12%;
                top: 30%;
            }
        }

        @media (max-width: 48em) {
            justify-content: flex-start;
            flex-direction: row;
            margin: 0;
        }
    }

    .next {
        display: flex;
        flex-direction: column;
        justify-content: center;
        margin-left: $s1;

        button {
            right: 10%;

            @media (max-width: 48em) {
                position: absolute;
                right: -12%;
                top: 30%;
            }
        }

        @media (max-width: 48em) {
            justify-content: flex-end;
            flex-direction: row;
            margin: 0;
        }
    }

    .slide {
        figure {
            margin: 0;
        }

        caption {
            display: block;
            padding-top: .5rem;
            text-align: left;
        }
    }
}</style>