<template>
    <svg width="23" height="14" viewBox="0 0 23 14" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M0.0482006 5.78348C1.68978 5.57472 3.02297 4.8678 4.00507 3.68644C4.79265 2.72806 5.3003 1.48976 5.52804 0L7.50647 1.15765C7.42107 1.40436 7.32618 1.65107 7.21706 1.88829C6.83276 2.71857 6.34883 3.43498 5.77949 4.02329C5.77949 4.02329 3.79631 5.81195 2.82845 5.81669H22.0435V7.9422H2.8237C3.79157 7.9422 5.77475 9.73561 5.77475 9.73561C6.34408 10.3239 6.82802 11.0403 7.21232 11.8706C7.32144 12.1126 7.41633 12.3545 7.50173 12.6013L5.52329 13.7589C5.3003 12.2691 4.79265 11.0308 4.00033 10.0725C3.01823 8.88635 1.68504 8.18417 0.043457 7.97541L0.0482006 5.78348Z" fill="#B1BCCA"/>
    </svg>
</template>

<style>

</style>

<script>
export default {
    name: 'IconArrowLeft',
};
</script>
