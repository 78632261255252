<template>
<div class="project-link" :class="{ 'full-width': isFullWidth }">
    <router-link :to="{ name: 'project', params: { slug: project.slug.current } }">
        <div class="preview-container" @mouseenter="onMouseOver" @mouseleave="onMouseOut">
            <div v-if="getImageUrl" class="initial-image">
                <img :src="getImageUrl" :alt="getImageAlt" />
            </div>
            <div v-if="getVideoUrl" class="hover-state">
                <video preload="none" muted>
                    <source :src="getVideoUrl" :type="getVideoMime">
                </video>
            </div>
        </div>
        <div class="info">
            <p class="h5-compact">{{ project.title }}</p>
            <p class="small org-name">{{ project.client.name }}</p>
        </div>
    </router-link>
</div>
</template>


<script>
export default {
    props: {
        'project': Object
    },
    computed: {
        isFullWidth() {
            return this.project.layout_work == 'full-width';
        },
        getImage() {
            if(this.project.image_wide && this.isFullWidth) {
                return this.project.image_wide.asset;
            } else if(this.project.image) {
                return this.project.image.asset;
            }
            return null;
        },
        getImageAlt() {
            if(!this.getImage) return;
            const image = this.getImage;
            return image.altText;
        },
        getImageUrl() {
            if(!this.getImage) return;
            const image = this.getImage;
            return image.url;
        },
        getVideo() {
            if(this.project.preview_wide && this.isFullWidth) {
                return this.project.preview_wide.asset;
            } else if(this.project.preview) {
                return this.project.preview.asset;
            }
            return null;
        },
        getVideoMime() {
            if(!this.getVideo) return;
            return this.getVideo.mimeType
        },
        getVideoUrl() {
            if(!this.getVideo) return;
            return this.getVideo.url
        },
    },
    methods: {
        onMouseOver(e) {
            for(const video of e.target.querySelectorAll('video')) {
                video.currentTime = 0;
                video.play();
            }
        },
        onMouseOut(e) {
            for(const video of e.target.querySelectorAll('video')) {
                video.pause();
            }
        }
    },
}
</script>


<style lang="scss" scoped>
.project-link {
    padding: $s3;

    a {
        text-decoration: none;

        .h5-compact {
            line-height: 32px;
        }

        p {
            margin-bottom: 0;

            &:not(.h5-compact) {
                margin-top: .6rem;
            }

        }
    }

    .preview-container {
        position: relative;
        width: 100%;
        padding-bottom: 100%;
        position: relative;

        // transition: 1s;
        >div {
            position: absolute;
            top: 0;
            left: 0;
            height: 100%;
            width: 100%;

            img {
                object-fit: cover;
                height: 100%;
                width: 100%;
                max-width: 100%;
            }

            video {
                object-fit: cover;
                height: 100%;
                width: 100%;
                max-width: 100%;
            }

            &.initial-image {
                transition: opacity 600ms ease-in-out;
            }

            &.hover-state {
                opacity: 0;
                transition: opacity 600ms ease-in-out;
            }
        }

        &:hover {
            div.initial-image {
                opacity: 0;
            }

            div.hover-state {
                opacity: 1;
            }
        }
    }

    .org-name {
        color: $upswell-light-blue;
        letter-spacing: 1px;
    }

    &.full-width {
        position: relative;
        $fw-image-height: 330px;

        .preview-container {
            padding-bottom: 0;
            height: $fw-image-height;

            >div {
                width: 100%;
                height: $fw-image-height;

                img {
                    object-fit: cover;
                    height: 100%;
                    width: 100%;
                }

                video {
                    object-fit: cover;
                    height: 100%;
                    width: 100%;
                }
            }
        }

        .info {
            position: absolute;
            bottom: calc(#{$s3} - 1px);
            left: calc(#{$s3} - 1px);
            width: 28ch;
            background: $upswell-blue;
            padding-right: $s5;
            height: 160px;
        }

        .info p {
            border: 4px sold red;
        }
    }

    @media screen and (max-width: 767px) {
        padding: 1.5rem;

        &.full-width {
            padding: 0;

            .info {
                position: relative;
                bottom: unset;
                left: unset;
                width: 28ch;
                background: $upswell-blue;
                padding-left: 1.5rem;
                padding-bottom: $s3;
                height: unset;
            }
        }
    }
}
</style>