import sanityClient from '@sanity/client'

const client = sanityClient({
    apiVersion: '2021-08-31',
    projectId: 'f0n06io6',
    dataset: 'production',
    useCdn: process.env.NODE_ENV === 'production'
})

export default client;
