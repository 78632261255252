<template>
    <section id="cookies" class="cookies" v-if="display">
        <div class="g-one">
            <p>Upswell uses cookies to improve your experience, see our <router-link to="/p/privacy-policy">Privacy Policy</router-link> for details.</p>
        </div>
        <div class="g-two">
            <div class="accept-wrapper">
                <button @click="onConfirmationClick">Got it!</button>
            </div>
        </div>
    </section>
</template>

<script>
const key = 'cookie.ack';

export default {
    name: 'Cookies',
    data() {
        return {
            display: false
        };
    },
    methods: {
        onConfirmationClick(e) {
            this.display = false
            localStorage.setItem(key, 1);
        }
    },
    mounted() {
        this.display = localStorage.getItem(key) != 1
    }
};
</script>

<style lang='scss' scoped>
.cookies {
    background-color: $upswell-blue-darker;
    bottom: 0;
    box-sizing: border-box;
    display: grid;
    left: 0;
    grid-template-columns: 2;
    padding: $s2;
    position: fixed;
    width: 100%;
    z-index: 1000;

    button {
        background: none;
        border: $upswell-yellow 1px solid;
        border-radius: 20px;
        color: $upswell-yellow;
        cursor: pointer;
        font-weight: bold;
        height: 40px;
        transition: 0.5s;
        width: 100px;

        &:hover {
            background-color: $upswell-yellow;
            color: $upswell-blue;
        }
    }

    p {
        margin: 5px 0 0 0;
    }

    .g-one {
        grid-column: 1;
        grid-row: 1;
    }

    .g-two {
        grid-column: 2;
        grid-row: 1;
        text-align: right;
    }

    @media screen and (max-width: 767px) {
        display: unset;

        .g-two {
            text-align: left;
            margin-top: $s2;
        }
    }
}
</style>

